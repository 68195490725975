import { config } from '../../../config';
import apiCall from '../../../commons/helpers/apiCall';

// Do not convert. Wrapper is not configured for external API's
export async function fetchSubmitSupportTicket(data) {
  const response = await fetch(`${config.commandLink}/ticket/webform`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: new URLSearchParams(data),
    redirect: 'follow',
  });
  const result = await response.json();
  return result;
}

// Connected
export async function fetchUserLogin(login) {
  const result = await apiCall({
    endpoint: 'customer/token',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: login,
      redirect: 'follow',
    },
  });
  return result;
}

// Connected
export async function fetchUserLogout(customerId) {
  const result = await apiCall({
    endpoint: 'customer/logout',
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: customerId,
      redirect: 'follow',
    },
  });
  return result;
}

// Connected
export async function fetchCustomerInfo(token) {
  const result = await apiCall({
    endpoint: `customers/me`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'customertoken': token,
      },
      redirect: 'follow',
    },
  });
  return result;
}

// Connected
export async function fetchResetPasswordLink(email) {
  const result = await apiCall({
    endpoint: 'customer/resetPassword',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: email,
      redirect: 'follow',
    },
  });
  return result;
}

// Connected
export async function fetchResetPasswordLinkV2(email) {
  const result = await apiCall({
    endpoint: 'reset/PasswordLink',
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: email,
      redirect: 'follow',
    },
  });
  return result;
}

// Incomplete
export async function fetchResetPassword(email, token, newPassword) {
  const result = await apiCall({
    endpoint: `customer/resetPassword/token/${token}`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: { newPassword: newPassword, email: email },
      redirect: 'follow',
    },
  });
  return result;
}

export async function fetchResetPasswordV2(email, token, newPassword) {
  const result = await apiCall({
    endpoint: 'user/resetPassword',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: { newPassword: newPassword, email: email, resetToken: token },
      redirect: 'follow',
    },
  });
  return result;
}

export async function validateMagicLink(email, token) {
  const result = await apiCall({
    endpoint: 'user/validate/token',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: { email: email, resetToken: token },
      redirect: 'follow',
    },
  });
  return result;
}

// Connected with one issue
export async function fetchUpdateFirstLastNames(data) {
  const result = await apiCall({
    endpoint: 'customer',
    version: 'V3',
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
      redirect: 'follow',
    },
  });
  return result;
}

// Connected
export async function fetchConfiguration() {
  const result = await apiCall({
    endpoint: `stores/configuration?path=general/store_information/support_hours`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
    authorize: false,
  });
  return result;
}
export async function fetchIpAddress() {
  const response = await apiCall({
    endpoint: `ipaddress`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
    authorize: false,
  });
  return response.result;
}

export async function postUiLogs(data) {
  const result = await apiCall({
    endpoint: 'uilogs',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
      redirect: 'follow',
    },
    authorize: false,
  });
  return result;
}

export async function userEmailPreference(email) {
  const result = await apiCall({
    endpoint: `/iterable-userdata/${email}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
    // authorize: false,
  });
  return result;
}

export async function emailPreferenceConfirmation(data) {
  const result = await apiCall({
    endpoint: `/iterable/emailSubscribe`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
      redirect: 'follow',
    },
    // authorize: false,
  });
  return result;
}
