import Script from 'next/script';
import { config } from '../../../config';
import { getUniqueBrowserID } from '../../helpers/Analytics/utils';
const uniqueExternalID = getUniqueBrowserID();
const index = () => {
  return (
    <>
      {/* Google Maps */}
      <Script
        strategy="beforeInteractive"
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAF9Gjn0-pIcoJI1ZgjfUxN-odFnUC70Ms&libraries=places"
      ></Script>

      {/* FB Pixel */}
      <Script id="fb-pixel" strategy="afterInteractive">
        {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${config.fb.pixel_id},{'external_id':  ${uniqueExternalID}});
            fbq('init', ${config.fb.pixel_id_sleep},{'external_id':  ${uniqueExternalID}});`}
      </Script>
      {/* Google Optimize */}
      {config.scripts.optimize && (
        <Script
          id="ggl-optmz"
          strategy="beforeInteractive"
          src={`https://www.googleoptimize.com/optimize.js?id=${config.ga.opt_container_id}`}
        />
      )}
      {/* VWO Script */}
      {config.scripts.vwo && (
        <Script
          id="vwoCode"
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: `window._vwo_code || (function() {
              var account_id = 886993,
                  version = 2.1,
                  settings_tolerance = 2000,
                  hide_element = 'body',
                  hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
                  /* DO NOT EDIT BELOW THIS LINE */
                  f = false,
                  w = window,
                  d = document,
                  v = d.querySelector('#vwoCode'),
                  cK = '_vwo_' + account_id + '_settings',
                  cc = {};
              try {
                  var c = JSON.parse(localStorage.getItem('_vwo_' + account_id + '_config'));
                  cc = c && typeof c === 'object' ? c : {}
              } catch (e) {}
              var stT = cc.stT === 'session' ? w.sessionStorage : w.localStorage;
              code = {
                  use_existing_jquery: function() {
                      return typeof use_existing_jquery !== 'undefined' ? use_existing_jquery : undefined
                  },
                  library_tolerance: function() {
                      return typeof library_tolerance !== 'undefined' ? library_tolerance : undefined
                  },
                  settings_tolerance: function() {
                      return cc.sT || settings_tolerance
                  },
                  hide_element_style: function() {
                      return '{' + (cc.hES || hide_element_style) + '}'
                  },
                  hide_element: function() {
                      if (performance.getEntriesByName('first-contentful-paint')[0]) {
                          return ''
                      }
                      return typeof cc.hE === 'string' ? cc.hE : hide_element
                  },
                  getVersion: function() {
                      return version
                  },
                  finish: function(e) {
                      if (!f) {
                          f = true;
                          var t = d.getElementById('_vis_opt_path_hides');
                          if (t) t.parentNode.removeChild(t);
                          if (e)(new Image).src = 'https://dev.visualwebsiteoptimizer.com/ee.gif?a=' + account_id + e
                      }
                  },
                  finished: function() {
                      return f
                  },
                  addScript: function(e) {
                      var t = d.createElement('script');
                      t.type = 'text/javascript';
                      if (e.src) {
                          t.src = e.src
                      } else {
                          t.text = e.text
                      }
                      d.getElementsByTagName('head')[0].appendChild(t)
                  },
                  load: function(e, t) {
                      var i = this.getSettings(),
                          n = d.createElement('script'),
                          r = this;
                      t = t || {};
                      if (i) {
                          n.textContent = i;
                          d.getElementsByTagName('head')[0].appendChild(n);
                          if (!w.VWO || VWO.caE) {
                              stT.removeItem(cK);
                              r.load(e)
                          }
                      } else {
                          var o = new XMLHttpRequest;
                          o.open('GET', e, true);
                          o.withCredentials = !t.dSC;
                          o.responseType = t.responseType || 'text';
                          o.onload = function() {
                              if (t.onloadCb) {
                                  return t.onloadCb(o, e)
                              }
                              if (o.status === 200) {
                                  _vwo_code.addScript({
                                      text: o.responseText
                                  })
                              } else {
                                  _vwo_code.finish('&e=loading_failure:' + e)
                              }
                          };
                          o.onerror = function() {
                              if (t.onerrorCb) {
                                  return t.onerrorCb(e)
                              }
                              _vwo_code.finish('&e=loading_failure:' + e)
                          };
                          o.send()
                      }
                  },
                  getSettings: function() {
                      try {
                          var e = stT.getItem(cK);
                          if (!e) {
                              return
                          }
                          e = JSON.parse(e);
                          if (Date.now() > e.e) {
                              stT.removeItem(cK);
                              return
                          }
                          return e.s
                      } catch (e) {
                          return
                      }
                  },
                  init: function() {
                      if (d.URL.indexOf('__vwo_disable__') > -1) return;
                      var e = this.settings_tolerance();
                      w._vwo_settings_timer = setTimeout(function() {
                          _vwo_code.finish();
                          stT.removeItem(cK)
                      }, e);
                      var t;
                      if (this.hide_element() !== 'body') {
                          t = d.createElement('style');
                          var i = this.hide_element(),
                              n = i ? i + this.hide_element_style() : '',
                              r = d.getElementsByTagName('head')[0];
                          t.setAttribute('id', '_vis_opt_path_hides');
                          v && t.setAttribute('nonce', v.nonce);
                          t.setAttribute('type', 'text/css');
                          if (t.styleSheet) t.styleSheet.cssText = n;
                          else t.appendChild(d.createTextNode(n));
                          r.appendChild(t)
                      } else {
                          t = d.getElementsByTagName('head')[0];
                          var n = d.createElement('div');
                          n.style.cssText = 'z-index: 2147483647 !important;position: fixed !important;left: 0 !important;top: 0 !important;width: 100% !important;height: 100% !important;background: white !important;';
                          n.setAttribute('id', '_vis_opt_path_hides');
                          n.classList.add('_vis_hide_layer');
                          t.parentNode.insertBefore(n, t.nextSibling)
                      }
                      var o = 'https://dev.visualwebsiteoptimizer.com/j.php?a=' + account_id + '&u=' + encodeURIComponent(d.URL) + '&vn=' + version;
                      if (w.location.search.indexOf('_vwo_xhr') !== -1) {
                          this.addScript({
                              src: o
                          })
                      } else {
                          this.load(o + '&x=true')
                      }
                  }
              };
              w._vwo_code = code;
              code.init();
          })();
        `,
          }}
        />
      )}
      {/* Crazy Egg */}
      {config.scripts.crazyegg && (
        <Script
          type="text/javascript"
          strategy="beforeInteractive"
          src="//script.crazyegg.com/pages/scripts/0118/8989.js"
        ></Script>
      )}

      {/* Yotpo Widget */}
      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `(function e() {
           let e = document.createElement("script");
           (e.type = "text/javascript"),
             (e.async = true),
             (e.src =
               "//cdn-widgetsrepository.yotpo.com/v1/loader/" + "${config.yotpo_id}" + "?languageCode=en");
           let t = document.getElementsByTagName("script")[0];
           t.parentNode.insertBefore(e, t);
         }
         )()`,
        }}
      ></Script>
      {/* Pinterest Tag */}
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `!(function (e) {
            if (!window.pintrk) {
              window.pintrk = function () {
                window.pintrk.queue.push(Array.prototype.slice.call(arguments));
              };
              var n = window.pintrk;
              (n.queue = []), (n.version = "3.0");
              var t = document.createElement("script");
              (t.async = !0), (t.src = e);
              var r = document.getElementsByTagName("script")[0];
          
              r.parentNode.insertBefore(t, r);
            }
          })("https://s.pinimg.com/ct/core.js");
          try {
            pintrk('load', ${config.pinterest.tracking_id});
            pintrk('page');
          } catch(er) {}`,
        }}
      ></Script>
      {/* Microsoft Clarity */}
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function (c, l, a, r, i, t, y) {
            c[a] =
              c[a] ||
              function () {
                (c[a].q = c[a].q || []).push(arguments);
              };
            t = l.createElement(r);
            t.async = 1;
            t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0];
            y.parentNode.insertBefore(t, y);
          })(window, document, "clarity", "script", "${config.ms_clarity}");
          `,
        }}
      ></Script>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        (function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push
        (arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g
        .parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A5228684-9e85-459b-a024-c7cef488f77f1.js','script','ire',document,window);
        `,
        }}
      ></Script>
    </>
  );
};

export const AdUnBlockerScripts = () => {
  return (
    <>
      {/* GTM */}
      <Script id="gtag-base" strategy="afterInteractive">
        {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${config.ga.tag_manager_id}');
          `}
      </Script>

      {/* Google Analytics - gtag */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${config.ga.measurement_id}`}
      />
      <Script id="gtag-init" strategy="afterInteractive">
      {`
        // Define the getLocalISOTime function
        const getLocalISOTime = () => {
        const now = new Date();
        const tzo = -now.getTimezoneOffset();
        const dif = tzo >= 0 ? '+' : '-';

        const pad = (num, size = 2) => {
        return String(num).padStart(size, '0');
        };

        return (
        now.getFullYear() +
        '-' +
        pad(now.getMonth() + 1) +
        '-' +
        pad(now.getDate()) +
        'T' +
        pad(now.getHours()) +
        ':' +
        pad(now.getMinutes()) +
        ':' +
        pad(now.getSeconds()) +
        '.' +
        pad(now.getMilliseconds(), 3) +
        dif +
        pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' +
        pad(Math.abs(tzo) % 60)
        );
        };

        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${config.ga.measurement_id}');

        gtag('get', '${config.ga.measurement_id}', 'client_id', function(clientId) {
        // Use getLocalISOTime to get the local time string
        const localTime = getLocalISOTime();

        // Send the custom event with page view data
        window.gtag('event', 'MTA-PageView', {
          page_title: document.title,
          page_path: window.location.pathname,
          HitTimestamp: localTime,
          ga_client_id: clientId,
          });
        });
        `}
      </Script>

    </>
  );
};

export const AdBlockerScript = () => {
  return (
    <>
      {/* <!-- Google tag (gtag.js) --> */}
      <Script>
        {`
        const GA_PROXY_URL = '${config.ga.proxy_url}';
        const GA_MEASUREMENT_ID = '${config.ga.measurement_id}';
        `}
      </Script>
      <Script async src="/js/plugins/globalscript.js"></Script>
      <Script>
        {`
        // Define the getLocalISOTime function
        const getLocalISOTime = () => {
        const now = new Date();
        const tzo = -now.getTimezoneOffset();
        const dif = tzo >= 0 ? '+' : '-';

        const pad = (num, size = 2) => {
        return String(num).padStart(size, '0');
        };

        return (
        now.getFullYear() +
        '-' +
        pad(now.getMonth() + 1) +
        '-' +
        pad(now.getDate()) +
        'T' +
        pad(now.getHours()) +
        ':' +
        pad(now.getMinutes()) +
        ':' +
        pad(now.getSeconds()) +
        '.' +
        pad(now.getMilliseconds(), 3) +
        dif +
        pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' +
        pad(Math.abs(tzo) % 60)
        );
        };
        
        

        
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${config.ga.measurement_id}');
        
        gtag('get', '${config.ga.measurement_id}', 'client_id', function(clientId) {
        // Use getLocalISOTime to get the local time string
        const localTime = getLocalISOTime();

        // Send the custom event with page view data
        window.gtag('event', 'MTA-PageView', {
          page_title: document.title,
          page_path: window.location.pathname,
          HitTimestamp: localTime,
          ga_client_id: clientId,
          });
        });
        
        `}
      </Script>
    </>
  );
};

export default index;
