import Router from 'next/router';
import { ContainedButton } from '..';
import styles from './UpsellComponent.module.scss';
import { Typography } from '@mui/material';
import { ProductsCategory } from '../../../config';
import {
  rfPainRelief,
  rfZenFactor,
  rfGoFactor,
  rfZFactor,
} from '../../../src/images/index';
import Image from 'next/image';

const UpsellComponent = ({
  cartItems,
  onAddProduct,
  textForSummary,
  ...rest
}) => {
  const ProductContent = [
    {
      id: 1,
      key: 'REL', // Add product key for matching with cart items
      productImg: rfPainRelief,
      category: 'Pain Relief',
      productName: 'Relief Factor',
      url: '/products/relief-factor',
      product: ProductsCategory.RELIEF_FACTOR,
    },
    {
      id: 2,
      key: 'ZFA',
      productImg: rfZFactor,
      category: 'Sleep Support',
      productName: 'Z Factor',
      url: '/products/z-factor',
      product: ProductsCategory.Z_FACTOR,
    },
    {
      id: 3,
      key: 'ZEN',
      productImg: rfZenFactor,
      category: 'Stress Response',
      productName: 'Zen Factor',
      url: '/products/zen-factor',
      product: ProductsCategory.ZEN_FACTOR,
    },
    {
      id: 4,
      key: 'GOF',
      productImg: rfGoFactor,
      category: 'Energy Boost',
      productName: 'Go Factor',
      url: '/products/go-factor',
      product: ProductsCategory.GO_FACTOR,
    },
  ];
  const miniProdCardClassMap = {
    [ProductsCategory.RELIEF_FACTOR]: styles.rel,
    [ProductsCategory.GO_FACTOR]: styles.gof,
    [ProductsCategory.ZEN_FACTOR]: styles.zen,
    [ProductsCategory.Z_FACTOR]: styles.zfa,
  };

  // Get the product keys from cart items
  const cartProductKeys = Object.values(cartItems).map((item) => item.category);

  // Find the first product that is not in the cart
  const nextProduct = ProductContent.find(
    (product) => !cartProductKeys.includes(product.key)
  );
  if (!nextProduct) return null;

  return (
    <div className={styles.upsellWrapper}>
      <Typography variant="h5" className={styles.upsellTitle}>
        {textForSummary ? 'Still Have Time to Add!' : 'You May Also Like'}
      </Typography>
      <div className={`${styles.miniProdCardContainer}`}>
        <div
          key={nextProduct.id}
          className={`${styles.miniProdCardContent} ${
            miniProdCardClassMap[nextProduct.product]
          }`}
        >
          <div className={styles.miniProdCardProduct}>
            <div className={styles.productCartImgContainer}>
              <Image
                component="img"
                className={styles.miniCartImg}
                placeholder="blur"
                blurDataURL={nextProduct.productImg}
                src={nextProduct.productImg}
                alt={nextProduct.productName}
                layout="fill"
              />
            </div>
            <div className={styles.textContainer}>
              <Typography className={styles.category} variant="h5">
                {nextProduct.category}
              </Typography>
              <Typography variant="h4" color="primary.main">
                {nextProduct.productName}
              </Typography>
              {/* <Typography className={styles.productPrice} variant="h5">
                ${nextProduct.productPrice} per unit
              </Typography> */}
            </div>
          </div>
          <div className={styles.miniProdCardBtnContainer}>
            <ContainedButton
              className={styles.btnStyle}
              onClick={() => {
                Router.push(
                  {
                    pathname: nextProduct.url,
                    hash: 'buy',
                  },
                  undefined,
                  { scroll: false }
                );
                if (onAddProduct) {
                  onAddProduct();
                }
              }}
            >
              + Add
            </ContainedButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpsellComponent;
