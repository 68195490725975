import React, { useRef, useState, useEffect } from 'react';
import styles from './MiniDrawer.module.scss';
//mui
import { Drawer, Typography, IconButton, useStepContext } from '@mui/material';
import { ContainedButton } from '../../../../commons/hoc';
import CartProductCard from '../../../../commons/components/ui/Cards/CartProductCard/CartProductCard';
import CloseIcon from './CloseIcon';
import { useOutsideClick } from '../../../../commons/hooks';
import { useAppDispatch, useAppSelector } from '../../../../src/app/hooks';
import {
  addItemToCart,
  getCartItems,
  getSubtotal,
  removeItemFromCart,
  getFreegift,
  addFreeGift,
  resetFreeGift,
  removeFreeGift,
} from '../../../../src/features/cart/cartState';
import {
  productMappedCategory,
  productUpgradeMapping,
} from '../../../../config/products';
import { productImages } from '../../../../src/images';
import CartEmpty from '../../../../src/pages/checkout/cartempty';
import BrokenCart from '../BrokenCart/BrokenCart';

import {
  getUI,
  isCartOpen,
  showCart,
} from '../../../../src/features/ui/uiState';
import Router from 'next/router';
import { getProducts } from '../../../../commons/utils/productsHelper';
import {
  GA4MiniCart,
  GA4RemoveCart,
} from '../../../../commons/helpers/Analytics/googleAnalytics';
import { FBAddToCart } from '../../../../commons/helpers/Analytics/fbPixel';
import { vwoHelper } from '../../../../commons/helpers/Analytics/vwo/vwoHelper';
import cookie from 'cookie';
import { config } from '../../../../config';
import UpsellComponent from '../../../../commons/hoc/Upsell/UpsellComponent';
import FreeGiftCard from '../../../../commons/components/ui/Cards/FreeGiftCard/FreeGiftCard';
import {
  productsKeyConstants,
  ProductsCategory,
} from '../../../../config/products';
import { CounterButton } from '../../../../commons/hoc';
import Image from 'next/image';

export default function MiniCartDrawer() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const cartItems = useAppSelector(getCartItems);
  const subTotal = useAppSelector(getSubtotal);
  const freeGiftDetails = useAppSelector(getFreegift);
  const [showQuantityInfo, setShowQuantityInfo] = useState({
    id: null,
    status: false,
  });
  const CounterButtonRef = useRef(null);
  const UpgradeModalRef = useRef(null);
  const infoRef = useRef(null);
  const cartOpen = useAppSelector(isCartOpen);
  const { cartLoading, brokenCart, quantityWarning } = useAppSelector(getUI);
  const [infoProduct, setInfoProduct] = useState(null);
  const [freeGifts, setFreeGifts] = useState([]);
  const [mainProductQty, setMainProductQty] = useState(0);
  const [freeGiftTotalQty, setFreeGiftTotalQty] = useState(0);
  const [showInfoMessageToast, setShowInfoMessageToast] = useState({
    status: false,
    msg: '',
  });
  const [hasRFProduct, setHasRFProduct] = useState(false);
  const [disableCheckoutBtn, setDisableCheckoutBtn] = useState(false);
  const products = getProducts();
  const [variationName, setVariationName] = useState('control-a');
  const closeFreeGiftError = () => {
    setTimeout(() => {
      setShowInfoMessageToast({
        status: false,
        msg: '',
        id: '',
      });
    }, 6000);
  };

  useEffect(() => {
    if (cartOpen) {
      document.body.classList.add('overflowHidden');
    } else {
      document.body.classList.remove('overflowHidden');
    }
    setShowInfoMessageToast({
      status: false,
      msg: '',
    });
    try {
      const userId = cookie.parse(document.cookie).vwo_uid;
      if (userId && config?.scripts?.vwo) {
        let userData = vwoHelper.vwoClientInstance?.userStorageService.get(
          userId,
          config.vwo.campaignKey
        );
        // console.log('userData minicart', userData);
        setVariationName(userData?.variationName || 'control-a');
      }
    } catch (err) {
      console.log(err);
    }
  }, [cartOpen]);

  useEffect(() => {
    setMainProductQty(
      Object.values(cartItems)?.find(
        (main) =>
          main?.productId ==
          products[productsKeyConstants.RF_RELIEF_FACTOR_QS].productId
      )?.quantity || 0
    );

    setHasRFProduct(
      Object.values(cartItems)?.some((res) =>
        [productsKeyConstants.RF_RELIEF_FACTOR_QS].includes(res?.key)
      )
    );
    //need to remove this typeof condition after prod release
    if (Array.isArray(freeGiftDetails)) {
      setFreeGiftTotalQty(
        freeGiftDetails.reduce(
          (accu, itemQuantity) => accu + itemQuantity.quantity,
          0
        )
      );
    }
  }, [cartItems, freeGiftDetails]);

  useEffect(() => {
    if (hasRFProduct) {
      let ProductData = products[productsKeyConstants.RF_RELIEF_FACTOR_QS];
      setFreeGifts(ProductData?.free_products);
    } else {
      setFreeGifts([]);
    }
  }, [hasRFProduct]);

  //For checking RF_RELIEF_FACTOR_QS is present on cart
  useEffect(() => {
    const hasRelSubscriptionProduct = Object.values(cartItems).some(
      (item) => item.key === productsKeyConstants.RF_RELIEF_FACTOR_QS
    );
    if (!hasRelSubscriptionProduct) {
      dispatch(resetFreeGift([]));
    }
  }, [cartItems]);

  useEffect(() => {
    if (showInfoMessageToast.status) {
      const span = infoRef.current;
      span.className = `${styles.quantityUpdateInfo} ${styles.fadeIn} ${styles.fast}`;
      setTimeout(function () {
        span.classList.remove(`${styles.fadeIn}`);
        span.className = `${styles.quantityUpdateInfo} ${styles.fadeOut} ${styles.fast}`;
      }, 5000);
    }
  }, [showInfoMessageToast]);

  useOutsideClick(UpgradeModalRef, () => {
    if (open) setOpen(false);
  });

  useEffect(() => {
    setShowQuantityInfo(quantityWarning);
  }, [quantityWarning]);

  const updateQuantity = (item, id, increase = true) => {
    const payload = {
      productId: id,
      data: {
        ...item,
        quantity: increase ? item.quantity + 1 : item.quantity - 1,
      },
    };

    dispatch(addItemToCart(payload));

    var productKey = item.key;
    var selectedProduct = products[productKey];
    const isSubscription = selectedProduct.isSubscription;
    var qtyUpdate = increase ? item.quantity + 1 : item.quantity - 1;
    var qtyChange = increase ? 'addQty' : 'subQty';
    var flag = false;
    if (selectedProduct.category == 'ZFA') {
      flag = true;
    }
    GA4MiniCart(0, selectedProduct, qtyUpdate, qtyChange);
    FBAddToCart(selectedProduct, isSubscription, qtyUpdate, flag);
    // FreeGift
    if (productKey === productsKeyConstants.RF_RELIEF_FACTOR_QS) {
      if (!increase && mainProductQty - 1 < freeGiftTotalQty) {
        setShowInfoMessageToast({
          status: true,
          msg: 'Change Free Gift quantity to match the actual product quantity.',
        });
        setDisableCheckoutBtn(true);
        if (infoRef.current) {
          infoRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
        closeFreeGiftError();
      } else {
        setDisableCheckoutBtn(false);
      }
    } else {
      if (mainProductQty < freeGiftTotalQty) {
        setShowInfoMessageToast({
          status: true,
          msg: 'Change Free Gift quantity to match the actual product quantity.',
        });
        setDisableCheckoutBtn(true);
        if (infoRef.current) {
          infoRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
        closeFreeGiftError();
      } else {
        setDisableCheckoutBtn(false);
      }
    }
  };

  const handleUpgradeClick = (item, id) => {
    let upgradedProduct = productUpgradeMapping[item.key];
    const payload = {
      productId: id,
      data: {
        ...item,
        ...productUpgradeMapping[item.key],
        quantity:
          item.quantity > products[upgradedProduct.key].maxQty
            ? products[upgradedProduct.key].maxQty
            : item.quantity,
      },
    };
    dispatch(addItemToCart(payload));

    var selectedProduct = products[upgradedProduct.key];
    const isSubscription = selectedProduct.isSubscription;
    var qtyUpdate = 0;
    var flag = false;
    if (selectedProduct.category == 'ZFA') {
      flag = true;
    }
    GA4MiniCart(0, selectedProduct, qtyUpdate, 'upgradeClick');
    FBAddToCart(selectedProduct, isSubscription, qtyUpdate, flag);
  };

  const handleRemoveClick = (item) => {
    var productKey = item.key;
    var removedProduct = products[productKey];
    const isSubscription = removedProduct.isSubscription;
    GA4RemoveCart(0, removedProduct, isSubscription);
    dispatch(
      removeItemFromCart({
        itemId: item.id,
        productId: item.productId,
      })
    );
    // Freegift
    if (removedProduct?.key == productsKeyConstants.RF_RELIEF_FACTOR_QS) {
      dispatch(resetFreeGift([]));
      setDisableCheckoutBtn(false);
    }
  };

  const handleInfoClicked = (item) => {
    setInfoProduct(item.key);
    setOpen(true);
  };

  const lastCheck = (i) => {
    if (i == Object.keys(cartItems).length - 1) {
      return true;
    }
    return false;
  };

  const checkCategory = (category) => {
    return productMappedCategory[category];
  };

  const isShowQuantityInfo = (key, qty) => {
    if (products[key]?.maxQty == qty) {
      return true;
    }
    return false;
  };

  const closeDrawer = () => {
    dispatch(showCart(false));
  };

  const onCheckoutHandler = () => {
    try {
      const userId = cookie.parse(document.cookie).vwo_uid;
      if (userId && config?.scripts?.vwo) {
        Object.values(cartItems).forEach((item) => {
          if (item.key.includes('REL')) {
            const selectedProduct = products[item.key];
            const options = {
              eventProperties: {
                'product-id': selectedProduct.productId,
                'product-name': selectedProduct.productName,
                'sku': selectedProduct.sku,
                'price': selectedProduct.recurringPrice,
                'quantity': item.quantity,
              },
            };
            vwoHelper.vwoClientInstance.track(
              config.vwo.campaignKey,
              userId,
              'checkout-button-clicked',
              options
            );
          }
        });
      }
    } catch (err) {
      console.log('VWO Error', err);
    }
    dispatch(showCart(false));
    Router.push('/checkout');
  };
  const onAddFreegift = (item, increase = true) => {
    const ProductData = products[productsKeyConstants.RF_RELIEF_FACTOR_QS];
    let payload = {};
    if (mainProductQty <= freeGiftTotalQty && increase) {
      setShowInfoMessageToast({
        status: true,
        msg: 'Sorry, gift quantity can’t exceed product quantity.',
        id: item.productId,
      });
      closeFreeGiftError();
      if (infoRef.current) {
        infoRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      return;
    }

    if (increase) {
      if (item.quantity > 0) {
        payload = { ...item };
        payload['quantity'] = item.quantity + 1;
        try {
          const userId = cookie.parse(document.cookie).vwo_uid;
          if (userId && config?.scripts?.vwo) {
            const options = {
              eventProperties: {
                name: item.name,
                sku: item.sku,
                quantity: item.quantity + 1,
                productId: item.productId,
              },
            };
            vwoHelper.vwoClientInstance.track(
              config.vwo.campaignKey,
              userId,
              'addFreeGift',
              options
            );
          }
        } catch (err) {
          console.log('VWO Error', err);
        }
      } else {
        payload['sku'] = item.sku;
        payload['parentProductId'] = ProductData.quoteParams.productId;
        payload['triggerType'] = ProductData.quoteParams.triggerType;
        payload['quantity'] = 1;
        payload['productId'] = item.productId;
        payload['name'] = item.name;
        try {
          const userId = cookie.parse(document.cookie).vwo_uid;
          if (userId && config?.scripts?.vwo) {
            const options = {
              eventProperties: {
                name: item.name,
                sku: item.sku,
                quantity: 1,
                productId: item.productId,
              },
            };
            vwoHelper.vwoClientInstance.track(
              config.vwo.campaignKey,
              userId,
              'addFreeGift',
              options
            );
          }
        } catch (err) {
          console.log('VWO Error', err);
        }
      }
    } else {
      payload = { ...item };
      payload['quantity'] = item.quantity - 1;
      try {
        const userId = cookie.parse(document.cookie).vwo_uid;
        if (userId && config?.scripts?.vwo) {
          const options = {
            eventProperties: {
              name: item.name,
              sku: item.sku,
              quantity: item.quantity - 1,
              productId: item.productId,
            },
          };
          vwoHelper.vwoClientInstance.track(
            config.vwo.campaignKey,
            userId,
            'removeFreeGift',
            options
          );
        }
      } catch (err) {
        console.log('VWO Error', err);
      }
    }

    dispatch(addFreeGift(payload));
    setShowInfoMessageToast({
      status: false,
      msg: '',
      id: '',
    });
    setDisableCheckoutBtn(false);
  };

  const onRemoveFreeGift = (item) => {
    dispatch(removeFreeGift(item));
    // FreeGift
    if (mainProductQty < freeGiftTotalQty - item.quantity) {
      setShowInfoMessageToast({
        status: true,
        msg: 'Change Free Gift quantity to match the actual product quantity.',
      });
      setDisableCheckoutBtn(true);
      closeFreeGiftError();
    } else {
      setDisableCheckoutBtn(false);
    }
    try {
      const userId = cookie.parse(document.cookie).vwo_uid;
      if (userId && config?.scripts?.vwo) {
        const options = {
          eventProperties: {
            name: item.name,
            sku: item.sku,
            quantity: 0,
            productId: item.productId,
          },
        };
        vwoHelper.vwoClientInstance.track(
          config.vwo.campaignKey,
          userId,
          'removeFreeGift',
          options
        );
      }
    } catch (err) {
      console.log('VWO Error', err);
    }
  };

  // Removing extra line from summary section
  const requiredCategories = [
    ProductsCategory.RELIEF_FACTOR,
    ProductsCategory.ZEN_FACTOR,
    ProductsCategory.GO_FACTOR,
    ProductsCategory.Z_FACTOR,
  ];
  const getCartCategories = (cartItems) => {
    const categories = new Set();
    Object.values(cartItems).forEach((item) => {
      categories.add(item.category);
    });
    return categories;
  };
  const cartCategories = getCartCategories(cartItems);
  const allRequiredCategoriesPresent = requiredCategories.every((category) =>
    cartCategories.has(category)
  );

  return (
    <>
      <Drawer
        anchor="right"
        open={cartOpen}
        onClose={() => closeDrawer()}
        ModalProps={{
          className: 'miniCartModal',
        }}
        sx={(theme) => ({
          [`& .MuiBackdrop-root`]: {
            backgroundColor: 'rgb(0, 65, 82, 0.3)',
          },
        })}
      >
        {/* Upgrade info modal */}
        {open && (
          <div className={styles.upgradeModalWrapper}>
            <div className={styles.upgradeModalContent}>
              <div
                className={styles.upgradeModalMainContent}
                ref={UpgradeModalRef}
              >
                <div className={styles.upgradeModalTitle}>
                  <div className={styles.upgradeTitle}>
                    SUBSCRIPTION TERMS & CONDITIONS
                  </div>
                  <div className={styles.upgradeModalClose}>
                    <IconButton
                      className={styles.upgradeModalCloseIcon}
                      onClick={() => setOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                <div className={styles.upgradeContent}>
                  <div className={styles.upgradeContentTitle}>
                    Monthly Subscription Plan:
                  </div>
                  <div
                    className={styles.upgradeContentDesc}
                    dangerouslySetInnerHTML={{
                      __html: products[infoProduct]?.disclaimer,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={styles.cartBgWrapper}>
          <div className={styles.cartWrapper}>
            <div className={styles.cartMainContainer}>
              <div className={styles.cartTitleSection}>
                <div>
                  <Typography variant="h3" color="primary">
                    Your Cart
                  </Typography>
                </div>
                <div>
                  <IconButton onClick={() => closeDrawer()}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              {brokenCart ? (
                <div className={styles.emptyCartContainer}>
                  <BrokenCart />
                </div>
              ) : Object.keys(cartItems)?.length > 0 ? (
                <>
                  <div ref={infoRef} className={styles.quantityUpdateInfo}>
                    {showInfoMessageToast.msg && showInfoMessageToast.msg}
                  </div>
                  <div className={styles.cartProductListWrapper}>
                    <div className={styles.cartProductListContainer}>
                      {Object.entries(cartItems)?.map(([id, item], i) => {
                        let key = item.key;
                        return (
                          <div className={styles.cartPoductCard} key={key}>
                            <CartProductCard
                              item={item}
                              counterRef={CounterButtonRef}
                              handleIncrementCounter={() =>
                                updateQuantity(item, id)
                              }
                              handleDecrementCounter={() =>
                                updateQuantity(item, id, false)
                              }
                              handleUpgrade={() => handleUpgradeClick(item, id)}
                              handleRemove={() => handleRemoveClick(item)}
                              category={checkCategory(item.category)}
                              handleInfo={() => handleInfoClicked(item)}
                              isLast={lastCheck(i)}
                              imgData={productImages[key]}
                              productName={products[key]?.productName}
                              showQuantityInfo={showQuantityInfo}
                              quantityInfoMsg={products[key]?.quantityInfoMsg}
                              incrementDisabled={isShowQuantityInfo(
                                key,
                                item.quantity
                              )}
                              decrementDisabled={false}
                              qty={item.quantity}
                              product={products[item.key]}
                              cartLoading={cartLoading}
                            />
                          </div>
                        );
                      })}
                      {freeGiftDetails?.length > 0 &&
                        freeGiftDetails?.map((item) => (
                          <div
                            className={styles.productCartWrapper}
                            key={item.id}
                          >
                            <div className={styles.productCartContainer}>
                              <div
                                className={
                                  styles.productCartTabDetailsContainer
                                }
                              >
                                <div className={styles.productCartImgContainer}>
                                  <Image
                                    src={productImages[item.productId]}
                                    layout="fill"
                                    placeholder="blur"
                                    blurDataURL={productImages[item.productId]}
                                    className={styles.productImg}
                                  />
                                </div>
                                <div
                                  className={
                                    styles.subscribedProductCartDetails
                                  }
                                >
                                  <div
                                    className={
                                      styles.productCategoryPriceContainer
                                    }
                                  >
                                    <Typography
                                      className={styles.productCartCategory}
                                    >
                                      {/* {category} */}Free Gift
                                    </Typography>
                                    <Typography
                                      className={`${styles.productPrice} `}
                                    >
                                      $0.00
                                    </Typography>
                                  </div>
                                  <div className={styles.productCartCount}>
                                    <Typography className={styles.productName}>
                                      {
                                        freeGifts?.find(
                                          (fg) => fg.productId == item.productId
                                        )?.name
                                      }
                                    </Typography>
                                    <div
                                      className={
                                        styles.counterRemoveButtonContainer
                                      }
                                    >
                                      <CounterButton
                                        // ref={counterRef}
                                        qty={item?.quantity}
                                        handleIncrementCounter={() =>
                                          onAddFreegift(item)
                                        }
                                        handleDecrementCounter={() =>
                                          onAddFreegift(item, false)
                                        }
                                        incrementDisabled={
                                          mainProductQty <= freeGiftTotalQty
                                        }
                                        decrementDisabled={false}
                                      />
                                      <button
                                        onClick={() => onRemoveFreeGift(item)}
                                        className={styles.productRemove}
                                        disabled={cartLoading}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              ) : (
                <div className={styles.emptyCartContainer}>
                  <CartEmpty />
                </div>
              )}
            </div>
            <div
              className={
                freeGiftDetails?.length != 2 || !allRequiredCategoriesPresent
                  ? styles.addItemToCart
                  : ''
              }
            >
              {hasRFProduct &&
                freeGiftDetails?.length != 2 &&
                mainProductQty > freeGiftTotalQty &&
                config.enableFreeGift && (
                  <div className={styles.freeGiftContainer}>
                    <FreeGiftCard
                      freeGiftItems={freeGifts}
                      onAddHandler={onAddFreegift}
                      mainProductQty={mainProductQty}
                      freeGiftTotalQty={freeGiftTotalQty}
                    />
                  </div>
                )}
              {Object.keys(cartItems)?.length > 0 &&
                !brokenCart &&
                config.enableUpsell && (
                  <UpsellComponent
                    cartItems={cartItems}
                    onAddProduct={closeDrawer}
                  />
                )}
            </div>

            {Object.keys(cartItems)?.length > 0 && !brokenCart && (
              <div className={styles.cartBtnWrapper}>
                <div className={styles.cartBtnContainer}>
                  <div className={styles.cartItemsTotal}>
                    <div>
                      <Typography variant="h5" color="primary.black">
                        Subtotal
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="h5" color="primary.black">
                        ${subTotal}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <ContainedButton
                      className={styles.cartCheckoutBtn}
                      onClick={() => {
                        onCheckoutHandler();
                      }}
                      disabled={cartLoading || disableCheckoutBtn}
                      isLoading={cartLoading}
                    >
                      Checkout
                    </ContainedButton>
                  </div>
                  <div className={styles.cartCheckoutInfo}>
                    Shipping + taxes calculated at checkout
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
}
