import React, { useRef, useState } from 'react';
import Image from 'next/image';
import { Typography, IconButton } from '@mui/material';
import { ContainedButton } from '../../../../hoc';

import styles from './FreeGiftCard.module.scss';
import { productImages } from '../../../../../src/images';

import { useAppSelector } from '../../../../../src/app/hooks';
import { getFreegift } from '../../../../../src/features/cart/cartState';
import ArrowIcon from '../../../../../components/mui5/homepage/bannerCarousal/ArrowIcon';
import { useMediaQuery } from '@mui/material';

const NavArrows = ({ handlePrev, handleNext, nextDisabled, prevDisabled }) => {
  return (
    <div className={styles.nav}>
      <IconButton
        className={styles.arrowsIcon}
        color="primary"
        size="medium"
        onClick={handlePrev}
        disabled={prevDisabled}
      >
        <ArrowIcon isRotated={true} color={'black'} />
      </IconButton>
      <IconButton
        className={styles.arrowsIcon}
        color="primary"
        size="medium"
        onClick={handleNext}
        disabled={nextDisabled}
      >
        <ArrowIcon isRotated={false} color={'black'} />
      </IconButton>
    </div>
  );
};

export default function FreeGiftCard({
  onAddHandler = () => {},
  freeGiftItems,
  fullWidth = false,
  mainProductQty,
  freeGiftTotalQty,
  isEditing,
  isSummary,
}) {
  const freeGiftItem = useAppSelector(getFreegift);
  const fgWrapper = useRef(null);
  const [disableNext, setDisableNext] = useState(false);
  const isMobile = useMediaQuery('(max-width:560px)');
  const isTab = useMediaQuery('(min-width:993px) and (max-width:1080px)');

  const nextHandler = () => {
    fgWrapper.current.scrollLeft = 400;
    setDisableNext(true);
  };
  const prevHandler = () => {
    fgWrapper.current.scrollLeft = 0;
    setDisableNext(false);
  };
  const freegiftSection = () => (
    <div
      className={`${styles.freeGiftContainerWrapper} ${
        fullWidth && styles.fullWidthPage
      }`}
    >
      <div className={styles.freeGiftTitleSection}>
        <Typography
          variant="h5"
          color="primary.black"
          className={styles.freeGiftTitle}
        >
          Claim Your Free Gift (with Relief Factor subscription)
        </Typography>{' '}
        {/* {freeGiftItem?.length == 0 && (!fullWidth || isMobile || isTab) && (
          <NavArrows
            prevDisabled={!disableNext}
            nextDisabled={disableNext}
            handleNext={() => nextHandler()}
            handlePrev={() => prevHandler()}
          />
        )} */}
      </div>

      <div className={styles.freeGiftWrapper} ref={fgWrapper}>
        {freeGiftItems.map((item) => {
          return (
            !freeGiftItem?.map((fg) => fg.sku).includes(item.sku) && (
              <div className={styles.productCardWrapper}>
                <div className={styles.productContentSection}>
                  <div className={styles.productImgWrapper}>
                    <Image
                      src={productImages[item.productId]}
                      layout="fill"
                      placeholder="blur"
                      blurDataURL={productImages[item.productId]}
                      className={styles.productImg}
                    />
                  </div>
                  <div className={styles.productdetails}>
                    <Typography variant="h5" color="primary.black">
                      {item?.name}
                    </Typography>
                    <Typography
                      variant="button"
                      color="primary.black"
                      className={styles.infoText}
                    >
                      {item?.dsc}
                    </Typography>
                  </div>
                </div>
                <div className={styles.productAction}>
                  <ContainedButton
                    onClick={() => onAddHandler(item)}
                    className={styles.actionBtn}
                  >
                    + Add
                  </ContainedButton>
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
  return (
    <>
      {isSummary
        ? isEditing && mainProductQty > freeGiftTotalQty && freegiftSection()
        : mainProductQty > freeGiftTotalQty && freegiftSection()}
    </>
  );
}
