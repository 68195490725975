// import RFBestDeal from './BestDeal-F.png';
const RFBestDeal = '/media/relief-factor-quickstart-guide.png';
// import RFSubSaveOffer2 from './relief-factor-60-vitd.png';
const RFSubSaveOffer2 = '/media/relief-factor-60-vitd.png';
// import RFSingleOrder from './relief-factor-60.png';
const RFSingleOrder = '/media/relief-factor-60.png';
// import RFEnergy from './relief-factor-energy.png';
const RFEnergy = '/media/relief-factor-energy.png';
// import RFCalm from './relief-factor-calm.png';
const RFCalm = '/media/relief-factor-calm.png';
const RFBug = '/media/rf-bug.png';
// const RFShareLogo = '/media/relief-factor-share-logo.png';
const RFShareLogo = '/media/og-image.png';
const SLPGaba = '/media/gaba.jpg';
const SLPLtheanine = '/media/l-theanine.jpg';
const SLPMelatonin = '/media/melatonin.jpg';
const SLPVitaminD = '/media/vitamin-d3.jpg';
import SLPWhySleepBG from './sleeping.jpg';
import SLPCloud from './clouds.jpg';
// import RFSleep from './relief-factor-sleep.png';
const RFSleep = '/media/relief-factor-sleep.png';
// import VitaminD from './Shield-VitD.jpg';
// const VitaminD = '/media/bottle-vitamin-d3.jpg';
const VitaminD = '/media/vitamin-d3.webp';
// import VitaminC from './Shield-VitC.jpg';
// const VitaminC = '/media/bottle-vitamin-c.jpg';
const VitaminC = '/media/vitamin-c.webp';
const turmericImageStr = '/media/turmeric.jpg';
const omega3 = '/media/omega-3.png';
// const icariin = '/media/icariin.png';
// const curcumin = '/media/curcumin.png';
// const resveratrol = '/media/resveratrol.png';
const mkOmega3 = '/media/feel-brand-new/omega-3.png';
const mkIcariin = '/media/feel-brand-new/icariin.png';
const mkTurmeric = '/media/feel-brand-new/turmeric.png';
const mkResveratrol = '/media/feel-brand-new/resveratrol.png';
const happyWomen = '/media/feel-brand-new/happy-women.png';
const researchImage = '/media/relief-factor-research.webp';
const RFQSTablets = '/media/relief-factor-qs-pills.png';
import { productsKeyConstants } from '../../config';
const yourSolutionImage = '/media/your-solution.png';
const quickstartImage = '/media/relief-factor-quickstart.png';
const happyCoupleImage = '/media/happy-couple.webp';
const joggingGuysImage = '/media/friends-jogging.webp';
const sleepingCoupleImage = '/media/sleeping-couple.webp';
const turmericBowlImage = '/media/turmeric-bowl.jpg';
const veteranImage = '/media/veteran-owned-business.png';
const reviewImage = '/media/portrait.jpg';
const rfFooterLogo = '/media/footer/rfc-logo-vert-all-white.png';
// for New site
const veteranImg = '/media/Veteran.png';
const painRelief = '/media/products/rf-pain-relief.webp';
const goFactor = '/media/products/rf-go-factor.webp';
const zenFactor = '/media/products/rf-zen-factor.webp';
const zFactor = '/media/products/rf-z-factor.webp';
const leaf1 = '/media/footer/Leaf-1.png';
const leaf2 = '/media/footer/Leaf-2.png';
const leaf3 = '/media/footer/Leaf-3.png';
const leaf4 = '/media/footer/Leaf-4.png';
const whyReliefFactorImg = '/media/why-relieffactor.webp';
const whyReliefFactorImgBlur = '/media/why-relieffactor-blur.webp';
const customerStoriesImg = '/media/customer-stories.webp';
const miniCartRelief = '/media/products/mini-cart-relief.png';
const resourceArticle = '/media/resource-article.png';
const resourceArticleBody = '/media/resource-article-body.png';
const rfLogoPng = '/media/RF-icon.png';
const pillsWithShadow = '/media/products/pills-with-shadow.png';
const pillWithShadow = '/media/products/pill-with-shadow.png';
const pillsPacket = '/media/products/pills-packet.png';
const rfPainRelief = '/media/products/rf-pain-relief.webp';
const rfZenFactor = '/media/products/rf-zen-factor.webp';
const rfZFactor = '/media/products/rf-z-factor.webp';
const rfGoFactor = '/media/products/rf-go-factor.webp';
const howItWorksImg1 = '/media/howItWorks/img1.webp';
const howItWorksImg2 = '/media/howItWorks/img2.webp';
const howItWorksImg3 = '/media/howItWorks/img3.webp';
const graph_howItWorks = '/media/howItWorks/graph.webp';
const Icariin = '/media/howItWorks/Icariin.webp';
const Resveratrol = '/media/howItWorks/Resveratrol.webp';
const Turmeric = '/media/howItWorks/Turmeric.webp';
const Omega3 = '/media/howItWorks/Omega3.webp';
const fullBleedImg = '/media/howItWorks/fullBleedFullImg.jpg';
const fullBleedImgSm = '/media/howItWorks/fullBleedSmImg.webp';
const fullBleedImgmd = '/media/howItWorks/fullBleedFullImgMd.jpg';
const rfZenFactor1 = '/media/products/rf-zen-factor-1.webp';
const rfZFactor1 = '/media/products/rf-z-factor-1.webp';
const rfGoFactor1 = '/media/products/rf-go-factor-1.webp';

const resveratrolImg = '/media/ingredients/Resveratrol.png';
const fishOilImg = '/media/ingredients/Fish-oil.png';
const turmericImg = '/media/ingredients/turmeric.png';
const icariinImg = '/media/ingredients/icariin.png';

const timelineImg1 = '/media/timeline-img-1.webp';
const timelineImg2 = '/media/timeline-img-2.webp';
const timelineImg3 = '/media/timeline-img-3.webp';

const testimonialsReliefImg = '/media/testimonials/testimonial-art-relief.jpg';
const testimonialsZenImg = '/media/testimonials/testimonial-drew-zen.jpg';
const testimonialsGoImg = '/media/testimonials/testimonial-melonie-go.jpg';
const testimonialszImg = '/media/testimonials/testimonial-brenda-z.jpg';

const RFQSTabletsOld = '/media/relief-factor-qs-pills.webp';
const RF_Icon = '/media/rf-icon.png';

// Ingredients
const curcumin = '/media/ingredients/rf/curcumin.png';
const icariin = '/media/ingredients/rf/icariin.png';
const omega = '/media/ingredients/rf/omega.png';
const resveratrol = '/media/ingredients/rf/resveratrol.png';

const taurine = '/media/ingredients/go/taurine.png';
const bcomplex = '/media/ingredients/go/b-complex.png';
const caffeine = '/media/ingredients/go/caffeine.png';

const phosphatidylserine = '/media/ingredients/zen/phosphatidylserine.png';
const passionflower = '/media/ingredients/zen/passion-flower.png';

const ltheanine = '/media/ingredients/z/l-theanine.png';
const vitamind = '/media/ingredients/z/vitamin-d.png';
const melatonin = '/media/ingredients/z/melatonin.png';
const gaba = '/media/ingredients/z/gaba.png';
// Ingredients

// Story
const sufferingWithPain = '/media/story/rf/suffering-with-pain.webp';
const findRelief = '/media/story/rf/find-relief.jpg';
const discoverReliefFactor = '/media/story/rf/discover-relief-factor.jpg';

const stressedOut = '/media/story/zen/stressed-out.webp';
const meditationPose = '/media/story/zen/meditation-pose.jpg';
const zenSuccess = '/media/story/zen/zen-success.webp';

const noEnergy = '/media/story/go/no-energy.webp';
const twoEnergyDrinks = '/media/story/go/two-energy-drinks.jpg';
const studying = '/media/story/go/studying.jpg';

const sleepless = '/media/story/z/sleepless.webp';
const sleeping = '/media/story/z/sleeping.jpg';
const rejuvenated = '/media/story/z/rejuvenated.jpg';
// Story

//Boday
const painReliefBody = '/media/body/painrelief.png';
const zenFactorBody = '/media/body/zenFactor.png';
const goFactorBody = '/media/body/goFactor.png';
const zFactorBody = '/media/body/zFactor.png';

//Review
const reviewHeaderDesktop = '/media/review/review-header-desktop.jpg';
const reviewHeaderTab = '/media/review/review-header-tab.jpg';
const reviewHeaderMobile = '/media/review/review-header-mobile.jpg';

//Support
const supportHeaderDesktop = '/media/support/hero-lg.png';
const supportHeaderTab = '/media/support/hero-md.jpg';

// Getting Started
const gettingStartedRFPills =
  '/media/product-gallery/rf/getting-started/relief-factor-pills.jpg';

const pillTwo = '/media/products/pill-two.png';
const pillFive = '/media/products/pill-five.png';

const testimonialFullImg1 = '/media/testimonials/drew-with-son.jpg';
const testimonialMbImg1 = '/media/testimonials/testimonialMbImg2.webp';
const testimonialFullImg2 = '/media/testimonials/dale-hiking.jpg';
const testimonialFullImg3 = '/media/testimonials/sandy-at-beach.jpg';
const testImg1 = '/media/testimonials/drewWithSon.jpg';
const testImg2 = '/media/testimonials/drewWithSonTabImg.jpg';
const testImg3 = '/media/testimonials/640-1.jpg';
const testImg4 = '/media/testimonials/daleHiking.jpg';
const testImg5 = '/media/testimonials/daleHikingTabImg2.jpg';
const testImg6 = '/media/testimonials/daleHikingMbImg.jpg';
const testImg7 = '/media/testimonials/sandyAtBeach.jpg';
const testImg8 = '/media/testimonials/1920-2.jpg';
const testImg9 = '/media/testimonials/sandyAtBeachMbImg.jpg';

export const productImages = {
  [productsKeyConstants.RF_QUICKSTART]: rfPainRelief,
  [productsKeyConstants.RF_60CT]: rfPainRelief,
  [productsKeyConstants.RF_60CT_SINGLE]: rfPainRelief,
  [productsKeyConstants.RF_ENERGY1]: rfGoFactor,
  [productsKeyConstants.RF_ENERGY2]: rfGoFactor,
  [productsKeyConstants.RF_ENERGY3]: rfGoFactor,
  [productsKeyConstants.RF_CALM]: rfZenFactor,
  [productsKeyConstants.RF_SLEEP]: rfZFactor,
  [productsKeyConstants.RF_RELIEF_FACTOR_QS]: rfPainRelief,
  [productsKeyConstants.RF_RELIEF_FACTOR_60_CT]: rfPainRelief,
  [productsKeyConstants.RF_RELIEF_FACTOR_60_CT_CA]: rfPainRelief,
  [productsKeyConstants.RF_RELIEF_FACTOR_60_CT_SINGLE]: rfPainRelief,
  [productsKeyConstants.RF_RELIEF_FACTOR_60_CT_CA_SINGLE]: rfPainRelief,
  [productsKeyConstants.RF_GO_FACTOR]: rfGoFactor,
  [productsKeyConstants.RF_GO_FACTOR_SINGLE]: rfGoFactor,
  [productsKeyConstants.RF_Z_FACTOR]: rfZFactor,
  [productsKeyConstants.RF_Z_FACTOR_SINGLE]: rfZFactor,
  [productsKeyConstants.RF_ZEN_FACTOR]: rfZenFactor,
  [productsKeyConstants.RF_ZEN_FACTOR_CA]: rfZenFactor,
  [productsKeyConstants.RF_ZEN_FACTOR_SINGLE]: rfZenFactor,
  [productsKeyConstants.RF_ZEN_FACTOR_CA_SINGLE]: rfZenFactor,
  115: rfPainRelief,
  116: rfPainRelief,
  117: rfZenFactor,
  118: rfGoFactor,
  119: rfZFactor,
  75: VitaminD,
  98: VitaminC,
  turmericImage: turmericImageStr,
  researchImage: researchImage,
  turmericImageStr,
  rfbug: RFBug,
  rfShareLogo: RFShareLogo,
  RFQSTablets,
  painRelief,
};

export const ingredientImages = {
  omega,
  icariin,
  curcumin,
  resveratrol,
};

export const assets = {
  SLPGaba,
  SLPLtheanine,
  SLPMelatonin,
  SLPVitaminD,
  SLPWhySleepBG,
  SLPCloud,
};

export const marketingPageAssets = {
  mkOmega3,
  mkIcariin,
  mkTurmeric,
  mkResveratrol,
  happyWomen,
};

export {
  yourSolutionImage,
  quickstartImage,
  happyCoupleImage,
  joggingGuysImage,
  sleepingCoupleImage,
  turmericBowlImage,
  veteranImage,
  reviewImage,
  // new site images
  painRelief,
  goFactor,
  zenFactor,
  zFactor,
  veteranImg,
  leaf1,
  leaf2,
  leaf3,
  leaf4,
  rfFooterLogo,
  whyReliefFactorImg,
  whyReliefFactorImgBlur,
  customerStoriesImg,
  miniCartRelief,
  resourceArticle,
  resourceArticleBody,
  rfLogoPng,
  // Product Cards
  pillsPacket,
  pillsWithShadow,
  pillWithShadow,
  rfPainRelief,
  rfGoFactor,
  rfZFactor,
  rfZenFactor,
  rfZenFactor1,
  rfZFactor1,
  rfGoFactor1,
  howItWorksImg1,
  howItWorksImg2,
  howItWorksImg3,
  graph_howItWorks,
  Icariin,
  Resveratrol,
  Turmeric,
  Omega3,
  fullBleedImg,
  fullBleedImgmd,
  fullBleedImgSm,
  //
  timelineImg1,
  timelineImg2,
  timelineImg3,
  resveratrolImg,
  fishOilImg,
  turmericImg,
  icariinImg,
  //
  RFQSTabletsOld,
  RF_Icon,
  //
  testimonialsReliefImg,
  testimonialsZenImg,
  testimonialsGoImg,
  testimonialszImg,
  //ingredients
  curcumin,
  icariin,
  omega,
  resveratrol,
  taurine,
  bcomplex,
  caffeine,
  phosphatidylserine,
  passionflower,
  ltheanine,
  gaba,
  vitamind,
  melatonin,
  // story
  sufferingWithPain,
  findRelief,
  discoverReliefFactor,
  zenSuccess,
  meditationPose,
  stressedOut,
  noEnergy,
  twoEnergyDrinks,
  studying,
  sleepless,
  sleeping,
  rejuvenated,
  // Body
  painReliefBody,
  zenFactorBody,
  goFactorBody,
  zFactorBody,
  // Getting Started
  gettingStartedRFPills,
  //Review
  reviewHeaderDesktop,
  reviewHeaderTab,
  reviewHeaderMobile,
  pillTwo,
  pillFive,
  // Support
  supportHeaderDesktop,
  supportHeaderTab,
  // Testimonial PDP
  testimonialFullImg1,
  testimonialMbImg1,
  testimonialFullImg2,
  testimonialFullImg3,
  testImg1,
  testImg2,
  testImg3,
  testImg4,
  testImg5,
  testImg6,
  testImg7,
  testImg8,
  testImg9,
};
