import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchSubmitSupportTicket,
  fetchUserLogin,
  fetchUserLogout,
  fetchCustomerInfo,
  fetchResetPasswordLink,
  fetchResetPassword,
  fetchResetPasswordV2,
  fetchUpdateFirstLastNames,
  fetchConfiguration,
  fetchIpAddress,
  fetchResetPasswordLinkV2,
  emailPreferenceConfirmation,
} from './userApi';
import router from 'next/router';
import { toast } from 'react-toastify';
import { SUCCESS_MESSAGES } from '../../../commons/feedbackMessages';
import {
  deleteToken,
  setToken,
} from '../../../commons/utils/extractCustomerToken';
import { UN_AUTHORIZED } from '../../../commons/Constants';

export const userState = {
  userToken: '',

  paymentUpdateAuth: {},

  customerInfo: {},
  subscriptionId: '',
  configDetails: {},
  otpCheckoutCode: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  shippingFirstName: '',
  shippingLastName: '',
  shippingPhone: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  billingFirstName: '',
  billingLastName: '',
  billingEmailAddress: '',
  billingPhoneNumber: '',
  billingAddress1: '',
  billingAddress2: '',
  billingCity: '',
  billingState: '',
  billingZipCode: '',
  selectedProduct: '',
  freeGift: '',
  shipsEvery: '',
  nextShipment: '',
  checkoutStep: '',
  highestCheckoutStep: '',
  checkoutStages: [],
  cancellationReasons: [],
  stageIsUnlocked: {
    information: null,
    shipping: null,
    payment: null,
    order: null,
  },
  billingAddressChecked: null,
  isShippingFirstNameTouched: null,
  isShippingLastNameTouched: null,
  isShippingPhoneNumberTouched: null,
  isBillingFirstNameTouched: null,
  isBillingLastNameTouched: null,
  shipping: {},
  billing: {},
  isBillingSameAsShipping: null,
  orderFor: {},
  supportTicket: {},
  ipAddress: null,
};

const initialState = {
  userToken: '',
  customerInfo: {},
  subscriptionId: '',
  configDetails: [{ value: '' }],
  otpCheckoutCode: Math.floor(1000 + Math.random() * 9000).toString(),
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  shippingFirstName: '',
  shippingLastName: '',
  shippingPhone: '',
  address1: '',
  address2: '',
  city: '',
  state: 'Select',
  zipCode: '',
  billingFirstName: '',
  billingLastName: '',
  billingEmailAddress: '',
  billingPhoneNumber: '',
  billingAddress1: '',
  billingAddress2: '',
  billingCity: '',
  billingState: 'Select',
  billingZipCode: '',
  selectedProduct: 'none',
  freeGift: 'none',
  shipsEvery: '',
  nextShipment: '',
  checkoutStep: 'information',
  highestCheckoutStep: 'information',
  checkoutStages: ['information', 'shipping', 'payment', 'order'],
  cancellationReasons: [],
  stageIsUnlocked: {
    information: true,
    shipping: false,
    payment: false,
    order: false,
  },
  billingAddressChecked: true,
  isShippingFirstNameTouched: false,
  isShippingLastNameTouched: false,
  isShippingPhoneNumberTouched: false,
  isBillingFirstNameTouched: false,
  isBillingLastNameTouched: false,
  shipping: null,
  billing: null,
  isBillingSameAsShipping: true,
  orderFor: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    trigger_sms: false,
  },
  supportTicket: null,
  ipAddress: null,
  subscriptionsModal: false,
};

export const submitSupportTicket = createAsyncThunk(
  'user/fetchSubmitSupportTicket',
  async (data) => {
    const response = await fetchSubmitSupportTicket(data);
    let supportTicketObj = {};
    if (response?.success) {
      supportTicketObj = {
        success: response.success == 'yes' ? true : false,
        ticketId: response.ticketid,
        internalId: response.internalid,
        message: response.message,
        time: response.time,
      };
    } else {
      supportTicketObj = {
        success: false,
        ticketId: null,
        message: `Something went wrong with submitting the form, Please try again later. Sorry for the inconvenience`,
        time: new Date(),
      };
    }
    return supportTicketObj;
  }
);

const orderResetValues = {
  shipping: null,
  billing: null,
  isBillingSameAsShipping: true,
  orderFor: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    trigger_sms: false,
  },
  checkoutStep: 'information',
  highestCheckoutStep: 'information',
  stageIsUnlocked: {
    information: true,
    shipping: false,
    payment: false,
    order: false,
  },
};

export const loginUser = createAsyncThunk(
  'user/fetchUserLogin',
  async (userData) => {
    const response = await fetchUserLogin({
      username: userData.username,
      password: userData.password,
    });
    return response.result;
  }
);

export const logoutUser = createAsyncThunk(
  'user/fetchUserLogout',
  async (customerId) => {
    const response = await fetchUserLogout({
      customerId: customerId,
    });
    return response.result;
  }
);

export const getCustomerInfo = createAsyncThunk(
  'user/fetchCustomerInfo',
  async (token) => {
    const response = await fetchCustomerInfo(token);
    return response;
  }
);

export const getIpAddressInfo = createAsyncThunk(
  'user/fetchIpAddress',
  async (token) => {
    const response = await fetchIpAddress(token);
    return response;
  }
);

export const resetPasswordLink = createAsyncThunk(
  'user/fetchResetPasswordLink',
  async (email) => {
    const response = await fetchResetPasswordLinkV2(email);
    return response;
  }
);

export const resetPassword = createAsyncThunk(
  'user/fetchResetPassword',
  async (data, { dispatch, getState }) => {
    const response = await fetchResetPassword(
      data.email,
      data.token,
      data.newPassword
    );
    const { user } = getState();
    dispatch(updateUserToken(''));
    dispatch(updateCustomerInfo({}));
    return response;
  }
);

export const resetPasswordV2 = createAsyncThunk(
  'user/fetchResetPasswordV2',
  async (data) => {
    const response = await fetchResetPasswordV2(
      data.email,
      data.resetToken,
      data.newPassword
    );
    return response.result;
  }
);

export const updateFirstLastNames = createAsyncThunk(
  'user/fetchUpdateFirstLastNames',
  async (data, { dispatch, getState }) => {
    const response = await fetchUpdateFirstLastNames(data);
    return response.result;
  }
);

export const getConfiguration = createAsyncThunk(
  'user/fetchConfiguration',
  async () => {
    const response = await fetchConfiguration();
    return response;
  }
);

export const postEmailPreference = createAsyncThunk(
  'user/emailPreference',
  async (data) => {
    const response = await emailPreferenceConfirmation(data);
    return response;
  }
);
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    selectProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    selectFreeGift: (state, action) => {
      state.freeGift = action.payload;
    },
    updateFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    updateLastName: (state, action) => {
      state.lastName = action.payload;
    },
    updateEmailAddress: (state, action) => {
      state.emailAddress = action.payload;
    },
    updatePhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    updateShippingPhoneNumber: (state, action) => {
      state.shippingPhone = action.payload;
    },
    updateShippingFirstName: (state, action) => {
      state.shippingFirstName = action.payload;
    },
    updateShippingLastName: (state, action) => {
      state.shippingLastName = action.payload;
    },
    updateAddress1: (state, action) => {
      state.address1 = action.payload;
    },
    updateAddress2: (state, action) => {
      state.address2 = action.payload;
    },
    updateCity: (state, action) => {
      state.city = action.payload;
    },
    updateState: (state, action) => {
      state.state = action.payload;
    },
    updateZipCode: (state, action) => {
      state.zipCode = action.payload;
    },
    updateBillingFirstName: (state, action) => {
      state.billingFirstName = action.payload;
    },
    updateBillingLastName: (state, action) => {
      state.billingLastName = action.payload;
    },
    updateBillingEmailAddress: (state, action) => {
      state.billingEmailAddress = action.payload;
    },
    updateBillingPhoneNumber: (state, action) => {
      state.billingPhoneNumber = action.payload;
    },
    updateBillingAddress1: (state, action) => {
      state.billingAddress1 = action.payload;
    },
    updateBillingAddress2: (state, action) => {
      state.billingAddress2 = action.payload;
    },
    updateBillingCity: (state, action) => {
      state.billingCity = action.payload;
    },
    updateBillingState: (state, action) => {
      state.billingState = action.payload;
    },
    updateBillingZipCode: (state, action) => {
      state.billingZipCode = action.payload;
    },
    updateShipsEvery: (state, action) => {
      state.shipsEvery = action.payload;
    },
    updateNextShipment: (state, action) => {
      state.nextShipment = action.payload;
    },
    updateCurrentCheckoutStep: (state, action) => {
      state.checkoutStep = action.payload;
    },
    updateCurrentHighestCheckoutStep: (state, action) => {
      state.highestCheckoutStep = action.payload;
    },
    updateStageIsUnlocked: (state, action) => {
      // state.stageIsUnlocked = {...state.stageIsUnlocked, [action.payload.key]: action.payload.value}
      state.stageIsUnlocked = { ...state.stageIsUnlocked, ...action.payload };
    },
    updateBillingAddressChecked: (state, action) => {
      state.billingAddressChecked = action.payload;
    },
    updateShippingFirstNameTouched: (state, action) => {
      state.isShippingFirstNameTouched = action.payload;
    },
    updateShippingLastNameTouched: (state, action) => {
      state.isShippingLastNameTouched = action.payload;
    },
    updateShippingPhoneNumberTouched: (state, action) => {
      state.isShippingPhoneNumberTouched = action.payload;
    },
    updateBillingFirstNameTouched: (state, action) => {
      state.isBillingFirstNameTouched = action.payload;
    },
    updateBillingLastNameTouched: (state, action) => {
      state.isBillingLastNameTouched = action.payload;
    },
    updateUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    updatePaymentAuth: (state, action) => {
      state.paymentUpdateAuth = action.payload;
    },
    updateCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    updateIpAddressInfo: (state, action) => {
      state.ipAddress = action.payload;
    },
    setShippingAddress: (state, action) => {
      state.shipping = action.payload;
    },
    setBillingAddress: (state, action) => {
      state.billing = action.payload;
    },
    setIsBillingSameAsShipping: (state, action) => {
      return {
        ...state,
        isBillingSameAsShipping: action.payload,
        // Todo: Don't nullify. Find alternative and persist.
        // billing: action.payload ? null : state.billing
      };
    },
    setOrderFor: (state, action) => {
      state.orderFor = { ...state.orderFor, ...action.payload };
    },
    updateAbandonCartData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetSupportTicket: (state) => {
      state.supportTicket = null;
    },
    resetUserCheckoutProgress: (state, action) => {
      return {
        ...state,
        ...orderResetValues,
      };
    },
    setEmailModal: (state, action) => {
      state.subscriptionsModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.userToken = action.payload;
        setToken(action.payload);
      })
      .addCase(logoutUser.fulfilled, (state) => {
        deleteToken();
        state.userToken = '';
        state.customerInfo = {};
      })
      .addCase(getCustomerInfo.fulfilled, (state, action) => {
        state.customerInfo = action.payload;
      })
      .addCase(getCustomerInfo.rejected, (state, action) => {
        state.userToken = '';
      })
      .addCase(getIpAddressInfo.fulfilled, (state, action) => {
        state.ipAddress = action.payload;
      })
      .addCase(getIpAddressInfo.rejected, (state, action) => {
        state.ipAddress = null;
      })
      .addCase(getConfiguration.fulfilled, (state, action) => {
        state.configDetails = action.payload;
      })
      .addCase(submitSupportTicket.fulfilled, (state, action) => {
        state.supportTicket = action.payload;
      })
      .addCase(submitSupportTicket.rejected, (state, action) => {
        state.supportTicket = {
          success: false,
          ticketId: null,
          message: `Something went wrong with submitting the form, Please try again later. Sorry for the inconvenience`,
          time: new Date(),
        };
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        router.push('/login');
      })
      .addCase(updateFirstLastNames.fulfilled, (state, action) => {
        toast.success(SUCCESS_MESSAGES.PASSWORD_CHANGE, {
          theme: 'colored',
        });
        state.userToken = action.payload;
        setToken(action.payload);
        router.push('/account');
      })
      .addCase(updateFirstLastNames.rejected, (state, action) => {
        toast.error(action?.error?.message || 'Something went wrong!', {
          theme: 'colored',
        });
      })
      .addCase(postEmailPreference.fulfilled, (state, action) => {
        state.subscriptionsModal = true;
        setTimeout(() => {
          state.subscriptionsModal = false;
        }, 600000);
      })
      .addCase(postEmailPreference.rejected, (state, action) => {
        state.subscriptionsModal = false;
        toast.error(action?.error?.message || 'Something went wrong!', {
          theme: 'colored',
        });
      })
      .addCase(resetPasswordV2.fulfilled, (state, action) => {
        state.userToken = action.payload;
        let url = '/account';
        if (
          state?.paymentUpdateAuth?.customerId &&
          state?.paymentUpdateAuth?.recurringId
        ) {
          url = '/paymentexpired';
        }
        router.replace(url);
      })
      .addCase(resetPasswordV2.rejected, (state, action) => {
        if (action.error) {
          if (
            action.error.message ===
              'The password token is expired. Reset and try again.' ||
            action.error.message ===
              'This link has expired. Please click Reset Password to get a new link.'
          ) {
            router.push(
              {
                pathname: '/forgotPassword',
                query: {
                  email: action.meta.arg.email,
                  fromMagicLink: true,
                },
              },
              '/forgotPassword'
            );
            toast.error(action?.error?.message, {
              theme: 'colored',
            });
          } else {
            toast.error(action?.error?.message || 'Something went wrong!', {
              theme: 'colored',
            });
          }
        } else {
          router.push('/login');
        }
      })
      .addMatcher(
        (action) =>
          action.type.endsWith('rejected') &&
          action?.error?.message === UN_AUTHORIZED,
        (state, action) => {
          deleteToken();
          state.userToken = '';
          state.customerInfo = {};
          if (state.shipping?.id) state.shipping.id = '';
          if (state.billing?.id) state.billing.id = '';
        }
      );
  },
});

export const {
  selectProduct,
  selectProductDetails,
  selectFreeGift,
  updateFirstName,
  updateLastName,
  updateEmailAddress,
  updatePhoneNumber,
  updateShippingFirstName,
  updateShippingLastName,
  updateShippingPhoneNumber,
  updateAddress1,
  updateAddress2,
  updateCity,
  updateState,
  updateZipCode,
  updateBillingFirstName,
  updateBillingLastName,
  updateBillingPhoneNumber,
  updateBillingAddress1,
  updateBillingAddress2,
  updateBillingCity,
  updateBillingState,
  updateBillingZipCode,
  updateShipsEvery,
  updateNextShipment,
  updateCurrentCheckoutStep,
  updateCurrentHighestCheckoutStep,
  updateStageIsUnlocked,
  updateBillingAddressChecked,
  updateShippingFirstNameTouched,
  updateShippingLastNameTouched,
  updateBillingFirstNameTouched,
  updateBillingLastNameTouched,
  updateShippingPhoneNumberTouched,
  updateUserToken,
  setOrderFor,
  resetSupportTicket,
  resetUserCheckoutProgress,
  updateCustomerInfo,
  updateIpAddressInfo,
  setShippingAddress,
  setBillingAddress,
  setIsBillingSameAsShipping,
  updatePaymentAuth,
  updateAbandonCartData,
  setEmailModal,
} = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getUser = (state) => state.user;
export const paymentUpdateAuthDetails = (state) => state.user.paymentUpdateAuth;
export const selectedProduct = (state) => state.user.selectedProduct;
export const freeGift = (state) => state.user.freeGift;
export const currentFirstName = (state) => state.user.firstName;
export const currentLastName = (state) => state.user.lastName;
export const currentShippingFirstName = (state) => state.user.shippingFirstName;
export const currentShippingLastName = (state) => state.user.shippingLastName;
export const currentEmailAddress = (state) => state.user.emailAddress;
export const currentPhoneNumber = (state) => state.user.phoneNumber;
export const currentShippingPhoneNumber = (state) => state.user.shippingPhone;
export const currentAddress1 = (state) => state.user.address1;
export const currentAddress2 = (state) => state.user.address2;
export const currentCity = (state) => state.user.city;
export const currentState = (state) => state.user.state;
export const currentZipCode = (state) => state.user.zipCode;
export const currentBillingFirstName = (state) => state.user.billingFirstName;
export const currentBillingLastName = (state) => state.user.billingLastName;
export const currentBillingEmailAddress = (state) =>
  state.user.billingEmailAddress;
export const currentBillingPhoneNumber = (state) =>
  state.user.billingPhoneNumber;
export const currentBillingAddress1 = (state) => state.user.billingAddress1;
export const currentBillingAddress2 = (state) => state.user.billingAddress2;
export const currentBillingCity = (state) => state.user.billingCity;
export const currentBillingState = (state) => state.user.billingState;
export const currentBillingZipCode = (state) => state.user.billingZipCode;
export const currentShipsEvery = (state) => state.user.shipsEvery;
export const currentNextShipment = (state) => state.user.nextShipment;
export const currentCheckoutStep = (state) => state.user.checkoutStep;
export const currentHighestCheckoutStep = (state) =>
  state.user.highestCheckoutStep;
export const currentStageIsUnlocked = (state) => state.user.stageIsUnlocked;
export const allCheckoutStages = (state) => state.user.checkoutStages;
export const addressBillingChecked = (state) =>
  state.user.billingAddressChecked;
export const currentIsShippingFirstNameTouched = (state) =>
  state.user.isShippingFirstNameTouched;
export const currentIsShippingLastNameTouched = (state) =>
  state.user.isShippingLastNameTouched;
export const currentIsBillingFirstNameTouched = (state) =>
  state.user.isBillingFirstNameTouched;
export const currentIsBillingLastNameTouched = (state) =>
  state.user.isBillingLastNameTouched;
export const currentIsShippingPhoneNumberTouched = (state) =>
  state.user.isShippingPhoneNumberTouched;
export const userToken = (state) => state.user.userToken;
export const customerInfo = (state) => state.user.customerInfo;
export const getCustomerId = (state) => state.user.customerInfo.id;
export const getCustomerEmail = (state) => state.user.customerInfo.email;
export const getCustomerEmailDeliverable = (state) =>
  state.user.customerInfo.custom_attributes;
export const otpCheckoutCode = (state) => state.user.otpCheckoutCode;
export const activeSubscriptions = (state) => state.user.activeSubscriptions;
export const subscriptionsLimit3 = (state) => state.user.subscriptionsLimit3;
export const subscriptionDetails = (state) => state.user.subscriptionDetails;
export const cancellationReasons = (state) => state.user.cancellationReasons;
export const configDetails = (state) => state.user.configDetails;
export const shippingInfo = (state) => state.user.shipping;
export const billingInfo = (state) => state.user.billing;
export const isBillingSameAsShipping = (state) =>
  state.user.isBillingSameAsShipping;
export const getOrderFor = (state) => state.user.orderFor;
export const supportTicketInfo = (state) => state.user.supportTicket;
export const ipAddressInfo = (state) => state.user.ipAddress;
export const getEmailModal = (state) => state.user.subscriptionsModal;

export default userSlice.reducer;
