import axios from 'axios';
import CryptoJS from 'crypto-js';
import requestIp from 'request-ip';
import { config } from '../../../config';
import store from '../../../src/app/store';
import { initialPriceProducts } from '../../Constants';
import { useAppSelector } from '../../../src/app/hooks';
import { getCart, getCartItems } from '../../../src/features/cart/cartState';

const getProductItems = (produtList, products, subMap = {}) => {
  const productItems = [];
  produtList.forEach(function (item, index) {
    const productData = products[item];
    const quoteParams = productData?.quoteParams;
    let data = {
      item_id: quoteParams.productId,
      id: quoteParams.productId,
      item_name: productData.name,
      name: productData.name,
      affiliation: 'Relief Factor',
      coupon: quoteParams.couponCode,
      currency: 'USD',
      discount: '',
      index: index,
      item_brand: '',
      item_category: productData.sku,
      category: productData.sku,
      item_category2: '',
      item_category3: '',
      item_category4: '',
      item_category5: productData.isSubscription,
      item_list_id: '',
      item_list_name: '',
      item_variant: '',
      location_id: '',
      price: productData.isSubscription
        ? productData.initialPrice
        : productData.single_price,
      quantity: quoteParams.quantity,
      // !modify the default condition
      // is_subscription: subMap[item] || false,
      is_subscription: productData.isSubscription,
    };
    productItems.push(data);
  });
  return productItems;
};

const getProductCartItems = (produtList, cart, products, subMap = {}) => {
  const productItems = [];

  if (cart) {
    var cartItems = cart.items;

    cartItems.forEach(function (item, index) {
      let data = {
        item_id: item.product_id,
        id: item.product_id,
        item_name: item.name,
        name: item.name,
        affiliation: 'Relief Factor',
        coupon: item.couponCode,
        currency: 'USD',
        discount: '',
        index: index,
        item_brand: '',
        item_category: item.sku,
        category: item.sku,
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_category5: item.is_subscription,
        item_list_id: '',
        item_list_name: '',
        item_variant: '',
        location_id: '',
        price: item.price,
        quantity: item.qty,
        // !modify the default condition
        // is_subscription: subMap[item] || false,
        is_subscription: item.is_subscription,
      };
      productItems.push(data);
    });
  }

  return productItems;
};

const getProductViewContent = (productList, products) => {
  let content_ids = [];
  let contents = [];
  let content_name = '';

  productList.forEach(function (item, index) {
    const productData = products[item];

    const quoteParams = productData?.quoteParams;
    const contentData = {
      id: quoteParams.productId,
      quantity: quoteParams.quantity,
    };
    content_name = productData.name;
    content_ids.push(quoteParams.productId);
    contents.push(contentData);
  });
  return {
    currency: 'USD',
    content_ids: [...new Set(content_ids)],
    content_type: 'product',
    contents: contents,
  };
};

const getProductList = (productList, products, cart) => {
  let content_ids = [];
  let contents = [];
  let content_name = '';

  var cartItems = cart?.items;

  cartItems?.forEach(function (item, index) {
    const contentData = {
      id: item.product_id,
      quantity: item.qty,
    };
    content_name = item.name;
    content_ids.push(item.product_id);
    contents.push(contentData);
  });
  return {
    currency: 'USD',
    content_ids: [...new Set(content_ids)],
    content_type: 'product',
    contents: contents,
  };
};
const getTwitterProductList = (productList, products, cart) => {
  let contentsData = [];
  var cartItems = cart?.items;

  cartItems?.forEach(function (item, index) {
    const data = {
      content_type: 'product',
      content_id: item.product_id,
      content_name: item.name,
      content_price: item.price,
      num_items: item.qty,
      content_group_id: null,
    };
    contentsData.push(data);
  });

  return contentsData;
};
const getProductSingleItem = (
  productData,
  recurringPrice = true,
  qtyUpdate
) => {
  const quoteParams = productData?.quoteParams;
  if (qtyUpdate) {
    var qty = qtyUpdate;
    var price = productData.initialPrice
      ? productData.initialPrice
      : productData.single_price;

    var calculatedPrice = qty * price;
    calculatedPrice = calculatedPrice.toFixed(2);
  } else {
    var qty = quoteParams.quantity;
    var calculatedPrice = recurringPrice
      ? productData.initialPrice
      : productData.single_price;
  }

  const contentData = {
    id: quoteParams.productId,
    quantity: qty,
  };
  const content_name = productData.name;

  const content_ids = [quoteParams.productId];
  const contents = [contentData];
  return {
    currency: 'USD',
    value: parseFloat(calculatedPrice),
    content_name: content_name,
    content_ids: content_ids,
    content_type: 'product',
    contents: contents,
  };
};

function getUniqueBrowserID() {
  if (typeof window !== 'undefined' && window) {
    let nav = window.navigator;
    let screen = window.screen;
    let guid = nav.mimeTypes.length;
    guid += nav.userAgent.replace(/\D+/g, '');
    guid += screen.height || '';
    guid += screen.width || '';
    guid += screen.pixelDepth || '';
    try {
      let FbExternalId = localStorage.getItem('FbExternalId');
      if (FbExternalId !== '' && FbExternalId != null) {
        return FbExternalId;
      } else {
        let uniqueID = guid.toString() + new Date().getTime().toString();
        localStorage.setItem('FbExternalId', uniqueID);
        return uniqueID;
      }
    } catch (e) {
      return guid.toString();
    }
  } else {
    return new Date().getTime();
  }
}

const getClientUserData = () => {
  const state = store.getState();
  if (typeof state.user.ipAddress !== 'undefined') {
    let clientIp = null;
    try {
      clientIp = state.user.ipAddress;
    } catch (err) {}
    let fbc = getCookieByName('_fbc');
    let fbp = getCookieByName('_fbp');
    let clientData = {
      client_ip_address: clientIp,
      client_user_agent: window.navigator.userAgent,
      external_id: getUniqueBrowserID(),
    };
    if (fbc !== '') {
      clientData['fbc'] = fbc;
    }
    if (fbp !== '') {
      clientData['fbp'] = fbp;
    }
    return clientData;
  }
};
const sentFbGraphAPIPost = (data, isSleep = false) => {
  try {
    const pixel_id = isSleep ? config.fb.pixel_id_sleep : config.fb.pixel_id;
    const client_ip_address = data[0].user_data.client_ip_address;
    if (pixel_id !== '' && client_ip_address != null) {
      const formData = {
        access_token: isSleep
          ? config.fb.access_token_sleep
          : config.fb.access_token,
        data: data,
      };
      const axis_config = {
        method: 'post',
        url: 'https://graph.facebook.com/v17.0/' + pixel_id + '/events',
        data: formData,
      };
      axios(axis_config)
        .then(function (response) {
          // success response
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  } catch (err) {}
};

const getCookieByName = (cname) => {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
const currentTimeStamp = Math.round(new Date().getTime() / 1000);

const encryptStrToSHA256 = (str) => {
  const hash = CryptoJS.SHA256(str);
  return hash.toString(CryptoJS.enc.Hex);
};

const getClientIp = (req) => {
  try {
    return requestIp.getClientIp(req);
  } catch {
    return '';
  }
};

const getUniqueEventId = () => {
  const max = 5000;
  let randomNo = Math.floor(Math.random() * (max - 0 + 1) + 0);
  let id = new Date().getTime() + randomNo.toString();
  return id;
};

const getURLWithoutQueryParam = () => {
  return window.location.href.split('?')[0];
};
const isFBNonBlockEvents = () => {
  const params = Object.keys(
    Object.fromEntries(new URLSearchParams(window.location.search))
  );
  const blockedParams = [
    'PubName',
    'Iterable_campaignname',
    'couponcode',
    'Iterable_email',
    'q',
    'source',
    'PC',
    'mode',
  ];
  const res = blockedParams.find((o2) =>
    params.some((o1) => o1.toLocaleLowerCase() == o2.toLocaleLowerCase())
  );
  if (typeof res == 'undefined') {
    return true;
  }
  return false;
};

const removeSpecialCharFromPhoneNo = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const firstNumber = cleaned.charAt(0);
  let match = '';
  if (firstNumber == 1) {
    let phoneNo = cleaned.substring(1);
    match = phoneNo.match(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{0,4})(?: *x(\d+))?\s*$/
    );
  } else {
    match = cleaned.match(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
    );
  }
  return match != null && Array.isArray(match) ? match[0] : '';
};

export {
  getProductItems,
  getProductList,
  getProductViewContent,
  getProductSingleItem,
  sentFbGraphAPIPost,
  getClientUserData,
  getProductCartItems,
  currentTimeStamp,
  getCookieByName,
  encryptStrToSHA256,
  getClientIp,
  getUniqueEventId,
  getURLWithoutQueryParam,
  getUniqueBrowserID,
  isFBNonBlockEvents,
  getTwitterProductList,
  removeSpecialCharFromPhoneNo,
};
