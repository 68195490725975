import store, { persistor } from '../app/store';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { useIsomorphicLayoutEffect } from '../../commons/hooks';
import ThemeProvider from '../styles/v5';
// 3rd Party
import Layout from '../../components/layout/Layout';
import { config } from '../../config';
// CSS
import '../styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';
import Helmet from './Helmet';
import SentrySetup from '../../components/Sentry';
import ThirdPartyScripts from '../../commons/components/scripts';
import { injectRiskified, isAppleDevice } from '../../commons/utils';
import { Head } from './../../components/global';
import { useEffect } from 'react';
import { FBPageView } from '../../commons/helpers/Analytics/fbPixel';
import ErrorBoundary from '../../components/ErrorBoundry';
import { vwoHelper } from '../../commons/helpers/Analytics/vwo/vwoHelper';
import cookie from 'cookie';
import { v4 as uuidv4 } from 'uuid';
import initVWOSdk from '../../commons/helpers/Analytics/vwo/vwoHelper';
function MyApp({ Component, pageProps, props }) {
  useIsomorphicLayoutEffect(async () => {
    injectRiskified();
    let purgeConfig = JSON.parse(localStorage?.getItem('__rdx_prst_prg'));
    if (
      config.redux.persist.purge &&
      (!purgeConfig?.version ||
        purgeConfig?.version !== config.redux.persist.version)
    ) {
      persistor.pause();
      persistor
        .flush()
        .then(() => persistor.purge().then(() => persistor.persist()));
      localStorage.setItem(
        '__rdx_prst_prg',
        JSON.stringify({
          version: config.redux.persist.version,
        })
      );
    }
  }, []);
  useEffect(() => {
    FBPageView();
    try {
      let bodySelector = document.querySelector('body');
      if (isAppleDevice()) {
        bodySelector.classList.remove('custom-scrollbar');
      } else bodySelector.classList.add('custom-scrollbar');
    } catch (err) {
      console.log(err);
    }
  }, []);
  try {
    //  VWO Settings
    let userId = props?.userId;

    if (userId && config?.scripts?.vwo) {
      let accountId = config.vwo.accountId;
      let sdkKey = config.vwo.sdkKey;
      const userData = {
        campaignKey: config.vwo.campaignKey,
        userId: userId,
        variationName: props.variationName,
      };
      let currentSettingsFile = {};
      vwoHelper
        .getSettingsFile(accountId, sdkKey)
        .then((latestSettingsFile) => {
          currentSettingsFile = latestSettingsFile;
          vwoHelper.currentSettingsFile = latestSettingsFile;
          vwoHelper.initVWOSdk(latestSettingsFile, userData, props);
        })
        .catch((err) => {
          console.error(
            'Something went wrong in fetching account settings.',
            err
          );
        });
    }
  } catch (err) {
    console.log('VWO Error:', err);
  }

  let updateProps = { ...pageProps, ...props };

  return config.sentry.enabled === true ? (
    <>
      <SentrySetup>
        <Provider store={store}>
          <ThemeProvider>
            <Head
              title={pageProps?.extraMeta?.title}
              description={pageProps?.extraMeta?.description}
              schemaData={pageProps?.extraMeta?.schemaData}
            />
            <Helmet />
            <Layout>
              <ErrorBoundary>
                <Component {...updateProps} />
              </ErrorBoundary>
            </Layout>
            <PersistGate loading={null} persistor={persistor} />
          </ThemeProvider>
        </Provider>
        <ThirdPartyScripts />
      </SentrySetup>
    </>
  ) : (
    <>
      <Provider store={store}>
        <ThemeProvider>
          <Head
            title={pageProps?.extraMeta?.title}
            description={pageProps?.extraMeta?.description}
            schemaData={pageProps?.extraMeta?.schemaData}
          />
          <Helmet />
          <Layout>
            <ErrorBoundary>
              <Component {...updateProps} />
            </ErrorBoundary>
          </Layout>
          <PersistGate loading={null} persistor={persistor} />
        </ThemeProvider>
      </Provider>
      <ThirdPartyScripts />
    </>
  );
}

export default MyApp;

MyApp.getInitialProps = async (context) => {
  let userId = null;
  let variationName = 'control-a';
  let userAgent = '';
  let userIP = '';

  try {
    userAgent = context?.ctx?.req?.headers['user-agent'] || '';
    userIP =
      context?.ctx?.req?.headers['x-real-ip'] ||
      context?.ctx?.req?.headers['x-forwarded-for'] ||
      context?.ctx?.req?.connection?.remoteAddress ||
      '';

    if (context?.ctx?.req?.cookies?.vwo_uid) {
      userId = context?.ctx?.req?.cookies?.vwo_uid;
    } else {
      userId = uuidv4();
      const setCookie = cookie.serialize('vwo_uid', userId, {
        httpOnly: false,
        maxAge: 86400,
        secure: false,
        path: '/',
      });
      context.ctx.res.setHeader('Set-Cookie', setCookie);
    }
    const vwoClientInstance = await initVWOSdk(config.vwo.sdkKey);
    let options = {
      userStorageData: vwoClientInstance.userStorageService,
      userAgent: userAgent,
      userIpAddress: userIP,
    };
    variationName = vwoClientInstance.activate(
      config.vwo.campaignKey,
      userId,
      options
    );
    const userData = {
      campaignKey: config.vwo.campaignKey,
      userId: userId,
      variationName: variationName,
    };
    const setUserStorageService =
      vwoClientInstance.userStorageService.set(userData);
  } catch (err) {
    console.log(err);
  }

  return {
    props: {
      userId,
      userAgent,
      userIP,
      variationName: variationName,
    },
  };
};
