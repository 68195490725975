const oldProductIDs = {
  'REL-49-01': {
    dev: '116',
    stage: '111',
    beta: '115',
    prod: '115',
  },
  'REL-60-01': {
    dev: '115',
    stage: '110',
    beta: '116',
    prod: '116',
  },
  'ENR-60-01': {
    dev: '114',
    stage: '112',
    beta: '118',
    prod: '118',
  },
  'CLM-60-01': {
    dev: '113',
    stage: '113',
    beta: '117',
    prod: '117',
  },
  'SLP-60-01': {
    dev: '118',
    stage: '116',
    beta: '119',
    prod: '119',
  },
};

const newProductIDs = {
  'REL-60-02': {
    dev: '120',
    stage: '118',
    beta: '121',
    prod: '121',
  },
  'REL-60-03': {
    dev: '121',
    stage: '119',
    beta: '122',
    prod: '122',
  },
  'GOF-60-01': {
    dev: '126',
    stage: '122',
    beta: '126',
    prod: '126',
  },
  'ZEN-60-01': {
    dev: '123',
    stage: '120',
    beta: '127',
    prod: '123',
  },
  'ZEN-60-02': {
    dev: '124',
    stage: '121',
    beta: '128',
    prod: '124',
  },
  'ZFA-60-01': {
    dev: '127',
    stage: '123',
    beta: '123',
    prod: '125',
  },
  'FREE_VITD': {
    dev: '98',
    stage: '98',
    beta: '75',
    prod: '75', // Need to Update Production ID
  },
  'FREE_VITC': {
    dev: '75',
    stage: '75',
    beta: '98',
    prod: '98', // Need to Update Production ID
  },
};

const mappedProductIDs = {
  ...oldProductIDs,
  ...newProductIDs,
};

const RF_QUICKSTART = 'REL-01';
const RF_60CT = 'REL-02';
const RF_60CT_SINGLE = 'REL-03';
const RF_ENERGY1 = 'ENR-01';
const RF_ENERGY2 = 'ENR-02';
const RF_ENERGY3 = 'ENR-03';
const RF_CALM = 'CLM-01';
const RF_SLEEP = 'SLP-01';
const RF_RELIEF_FACTOR_QS = 'REL-04';
const RF_RELIEF_FACTOR_60_CT = 'REL-05';
const RF_RELIEF_FACTOR_60_CT_CA = 'REL-06';
const RF_RELIEF_FACTOR_60_CT_SINGLE = 'REL-07';
const RF_RELIEF_FACTOR_60_CT_CA_SINGLE = 'REL-08';
const RF_GO_FACTOR = 'GOF-01';
const RF_GO_FACTOR_SINGLE = 'GOF-02';
const RF_ZEN_FACTOR = 'ZEN-01';
const RF_ZEN_FACTOR_CA = 'ZEN-02';
const RF_ZEN_FACTOR_SINGLE = 'ZEN-03';
const RF_ZEN_FACTOR_CA_SINGLE = 'ZEN-04';
const RF_Z_FACTOR = 'ZFA-01';
const RF_Z_FACTOR_SINGLE = 'ZFA-02';

export const oldProductsKeyConstants = {
  RF_QUICKSTART,
  RF_60CT,
  RF_60CT_SINGLE,
  RF_ENERGY1,
  RF_ENERGY2,
  RF_ENERGY3,
  RF_CALM,
  RF_SLEEP,
};

export const newProductsKeyConstants = {
  RF_RELIEF_FACTOR_QS,
  RF_RELIEF_FACTOR_60_CT,
  RF_RELIEF_FACTOR_60_CT_CA,
  RF_RELIEF_FACTOR_60_CT_SINGLE,
  RF_RELIEF_FACTOR_60_CT_CA_SINGLE,
  RF_GO_FACTOR,
  RF_GO_FACTOR_SINGLE,
  RF_ZEN_FACTOR,
  RF_ZEN_FACTOR_CA,
  RF_ZEN_FACTOR_SINGLE,
  RF_ZEN_FACTOR_CA_SINGLE,
  RF_Z_FACTOR,
  RF_Z_FACTOR_SINGLE,
};

const productsKeyConstants = {
  ...oldProductsKeyConstants,
  ...newProductsKeyConstants,
};
const ProductsCategory = {
  RELIEF_FACTOR: 'REL',
  GO_FACTOR: 'GOF',
  ZEN_FACTOR: 'ZEN',
  Z_FACTOR: 'ZFA',
};

export const productMappedCategory = {
  REL: 'Pain Relief',
  GOF: 'Energy Boost',
  ZEN: 'Anxiety Relief',
  ZFA: 'Sleep Support',
};
export const productUpgradeMapping = {
  [RF_RELIEF_FACTOR_60_CT_SINGLE]: {
    key: RF_RELIEF_FACTOR_QS,
    isSubscription: true,
    category: 'REL',
    quantity: 1,
  },
  [RF_GO_FACTOR_SINGLE]: {
    key: RF_GO_FACTOR,
    isSubscription: true,
    category: 'GOF',
    quantity: 1,
  },
  [RF_ZEN_FACTOR_SINGLE]: {
    key: RF_ZEN_FACTOR,
    isSubscription: true,
    category: 'ZEN',
    quantity: 1,
  },
  [RF_Z_FACTOR_SINGLE]: {
    key: RF_Z_FACTOR,
    isSubscription: true,
    category: 'ZFA',
    quantity: 1,
  },
};

let dbMapping = {
  dev: 'dev',
  develop: 'dev',
  development: 'dev',
  stage: 'stage',
  staging: 'stage',
  beta: 'beta',
  production: 'prod',
  prod: 'prod',
};

let db = dbMapping[process.env.NEXT_PUBLIC_DB_ENV] || 'dev';

export const productAffiliateMapping = {
  ['rfqs']: '/products/relief-factor',
  ['rfct60']: '/products/relief-factor',
  ['rfgof']: '/products/go-factor',
  ['rfzen']: '/products/zen-factor',
  ['rfzfa']: '/products/z-factor',
};

export const oldProducts = {
  [RF_QUICKSTART]: {
    key: RF_QUICKSTART,
    category: 'REL',
    disclaimer:
      "For your convenience, your purchase of QuickStart is followed by a full month's supply (60 packets) of Relief Factor shipped in 3 weeks and then every month thereafter unless you cancel. Your card will be charged $79.95 + $6.95 S&H each time your next supply ships. To cancel, call <a href='tel:+1833-888-1533'>833-888-1533</a> or send an email to <a href='mailto:contactus@relieffactor.com'>contactus@relieffactor.com</a> at least 1 day before your next supply ships.",
    initialPrice: '19.95',
    isSubscription: true,
    imageUrl:
      'https://m2beta-frontend.relieffactor.com/media/catalog/product/r/f/rfqs-wfreeguide_1__1__2.png',
    name: '3-Week QuickStart',
    sku: 'REL-49-01',
    productId: mappedProductIDs['REL-49-01'][db],
    productName: 'Relief Factor 3-Week QuickStart',
    quoteParams: {
      productId: mappedProductIDs['REL-49-01'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: '98eb55e1-658b-4f2e-a71e-844b093699b1',
      offerId: 'd8210b55-0465-46ea-a843-831c03a57ab2',
      triggerType: '2',
      //      existingItemsOffer: [],
    },
    recurringPrice: '79.95',
  },
  [RF_60CT]: {
    key: RF_60CT,
    category: 'REL',
    disclaimer:
      'For your convenience, when you purchase Relief Factor Initial 60 Pack at the Subscribe & Save price, your next order will be shipped in one month and then every month thereafter until you cancel. Your card will be charged $79.95 + $6.95 S&H each time your next supply ships. To cancel, call <span>833-888-1533</span> or send an email to <a href="mailto:contactus@relieffactor.com">contactus@relieffactor.com</a> at least 1 day before your next supply ships.',
    initialPrice: '79.95',
    isSubscription: true,
    imageUrl:
      'https://m2beta-frontend.relieffactor.com/media/catalog/product/r/f/rf60-wfree_1_3.png',
    name: '60-ct Bag',
    sku: 'REL-60-01',
    productId: mappedProductIDs['REL-60-01'][db],
    productName: 'Relief Factor 60 Pack',
    quoteParams: {
      productId: mappedProductIDs['REL-60-01'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: '98eb55e1-658b-4f2e-a71e-844b093699b1',
      offerId: 'f693de48-4b3f-4a20-9c65-19f5111e9734',
      triggerType: '3',
      //      existingItemsOffer: [],
    },
    recurringPrice: '79.95',
  },
  [RF_60CT_SINGLE]: {
    key: RF_60CT_SINGLE,
    category: 'REL',
    disclaimer: '',
    initialPrice: '93.95',
    isSubscription: false,
    imageUrl:
      'https://m2beta-frontend.relieffactor.com/media/catalog/product/r/f/rf60-front_1_3.png',
    name: '60-ct Bag',
    sku: 'REL-60-01',
    productId: mappedProductIDs['REL-60-01'][db],
    productName: 'Relief Factor 60 Pack',
    quoteParams: {
      productId: mappedProductIDs['REL-60-01'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: '98eb55e1-658b-4f2e-a71e-844b093699b1',
      offerId: 'f693de48-4b3f-4a20-9c65-19f5111e9734',
      //      triggerType: '',
      //      existingItemsOffer: [],
    },
    recurringPrice: '0.00',
  },
  [RF_ENERGY1]: {
    key: RF_ENERGY1,
    category: 'ENR',
    disclaimer:
      "When you purchase Go Factor you will automatically receive the regular 60-capsules supply shipped in 30 days and then monthly thereafter unless you cancel. Your card will be charged $29.95 + applicable state taxes each time your supply ships. To cancel, call <a href='tel:+1833-888-1533'>833-888-1533</a> or send an email to <a href='mailto:contactus@relieffactor.com'>contactus@relieffactor.com</a> at least 1 day before your next supply ships.",
    initialPrice: '34.95',
    isSubscription: true,
    imageUrl:
      'https://m2beta-frontend.relieffactor.com/media/catalog/product/e/n/energy-pills-single_10.png',
    name: '1 Bottle',
    sku: 'ENR-60-01',
    productId: mappedProductIDs['ENR-60-01'][db],
    productName: 'Go Factor (60-capsules)',
    quoteParams: {
      productId: mappedProductIDs['ENR-60-01'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: '61cb60a9-3f56-4cfd-8b11-69a2ff790441',
      offerId: '8f1b2495-7f54-4aef-bf07-a7afd18f5aaf',
      //      triggerType: '',
      //      existingItemsOffer: [],
    },
    recurringPrice: '29.95',
  },
  [RF_ENERGY2]: {
    key: RF_ENERGY2,
    category: 'ENR',
    disclaimer:
      "When you purchase Go Factor you will automatically receive the regular 180-capsules supply shipped in 90 days and then monthly thereafter unless you cancel. Your card will be charged $24.85 + applicable state taxes each time your supply ships. To cancel, call <a href='tel:+1833-888-1533'>833-888-1533</a> or send an email to <a href='mailto:contactus@relieffactor.com'>contactus@relieffactor.com</a> at least 1 day before your next supply ships.",
    initialPrice: '29.95',
    isSubscription: true,
    imageUrl:
      'https://m2beta-frontend.relieffactor.com/media/catalog/product/e/n/energy-pills-three_5.png',
    name: '3 Bottles',
    sku: 'ENR-60-01',
    productId: mappedProductIDs['ENR-60-01'][db],
    productName: 'Go Factor (60-capsules)',
    quoteParams: {
      productId: mappedProductIDs['ENR-60-01'][db],
      //customerId: '',
      quantity: 3,
      //      couponCode: '',
      //      freeProducts: [],
      planId: '61cb60a9-3f56-4cfd-8b11-69a2ff790441',
      offerId: '7b8763a1-9acc-45e1-ae20-5cb8be777e93',
      //      triggerType: '',
      //      existingItemsOffer: [],
    },
    recurringPrice: '24.85',
  },
  [RF_ENERGY3]: {
    key: RF_ENERGY3,
    category: 'ENR',
    disclaimer:
      'When you purchase Go Factor you will automatically receive the regular 360-capsules supply shipped in 180 days and then monthly thereafter unless you cancel. Your card will be charged $19.95 + applicable state taxes each time your supply ships. To cancel, call <a href="tel:+1833-888-1533">833-888-1533</a> or send an email to <a href="mailto:contactus@relieffactor.com">contactus@relieffactor.com</a> at least 1 day before your next supply ships.',
    initialPrice: '24.95',
    isSubscription: true,
    imageUrl:
      'https://m2beta-frontend.relieffactor.com/media/catalog/product/e/n/energy-pills-six_4.png',
    name: '6 Bottles',
    sku: 'ENR-60-01',
    productId: mappedProductIDs['ENR-60-01'][db],
    productName: 'Go Factor (60-capsules)',
    quoteParams: {
      productId: mappedProductIDs['ENR-60-01'][db],
      //customerId: '',
      quantity: 6,
      //      couponCode: '',
      //      freeProducts: [],
      planId: '61cb60a9-3f56-4cfd-8b11-69a2ff790441',
      offerId: '9db1923d-5d38-424a-a93c-25a3fbaac4c5',
      //      triggerType: '',
      //      existingItemsOffer: [],
    },
    recurringPrice: '19.95',
  },
  [RF_CALM]: {
    key: RF_CALM,
    category: 'CLM',
    disclaimer: `When you purchase Zen Factor you will automatically receive the regular 60-capsule supply shipped in 30 days and then monthly thereafter unless you cancel. Your card will be charged $21.95 + applicable state taxes each time your supply ships. To cancel, call <a href="tel:+1833-888-1533">833-888-1533</a>
     or send an email to <a href='mailto:contactus@relieffactor.com'>contactus@relieffactor.com</a> at least 1 day before your next supply ships.`,
    initialPrice: '28.95',
    isSubscription: true,
    imageUrl:
      'https://m2beta-frontend.relieffactor.com/media/catalog/product/r/f/rfcalm_8.png',
    name: 'Zen Factor (60 count)',
    sku: 'CLM-60-01',
    productId: mappedProductIDs['CLM-60-01'][db],
    productName: 'Zen Factor (60 count)',
    quoteParams: {
      productId: mappedProductIDs['CLM-60-01'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: 'b5e92d67-92c8-4d8d-9f35-be925b99633f',
      offerId: '6c546628-6435-4490-8675-4097e4f87f52',
      //      triggerType: '',
      //      existingItemsOffer: [],
    },
    recurringPrice: '21.95',
  },
  [RF_SLEEP]: {
    key: RF_SLEEP,
    category: 'SLP',
    disclaimer: `When you purchase Z Factor you will automatically receive the regular 60-capsule supply shipped in 30 days and then monthly thereafter unless you cancel. Your card will be charged $29.95 + applicable state taxes each time your supply ships. To cancel, call <a href="tel:+1833-888-1533">833-888-1533</a> or send an email to <a href='mailto:contactus@relieffactor.com'>contactus@relieffactor.com</a> at least 1 day before your next supply ships.`,
    initialPrice: '19.95',
    isSubscription: true,
    imageUrl:
      'https://m2beta-frontend.relieffactor.com/media/catalog/product/r/f/rf-sleep-500.png',
    name: 'Z Factor (60 count)',
    sku: 'SLP-60-01',
    productId: mappedProductIDs['SLP-60-01'][db],
    productName: 'Z Factor (60 count)',
    quoteParams: {
      productId: mappedProductIDs['SLP-60-01'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: '11d07b11-0cd4-45be-b42e-4285f2d5b4a7',
      offerId: 'f95176e3-5730-4ae2-83ce-c4e5a1038b1f',
      //      triggerType: '',
      //      existingItemsOffer: [],
    },
    recurringPrice: '29.95',
  },
};

export const newProducts = {
  [RF_RELIEF_FACTOR_QS]: {
    key: RF_RELIEF_FACTOR_QS,
    title: 'Relief Factor',
    name: 'Relief Factor (60 count)',
    productName: 'Relief Factor (60 count)',
    category: 'REL',
    isSubscription: true,
    displayPrice: 19.95,
    initialPrice: '19.95',
    recurringPrice: '79.95',
    single_price: '93.95',
    sku: 'REL-60-02',
    productId: mappedProductIDs['REL-60-02'][db],
    CA_sku: 'REL-60-03',
    CA_product_id: mappedProductIDs['REL-60-03'][db],
    free_products: [
      {
        sku: 'FREE_VITD',
        name: 'Vitamin D3',
        image_url:
          'https://m2stage.relieffactor.com/media/catalog/product/s/h/shield-vitd.jpg',
        productId: mappedProductIDs['FREE_VITD'][db],
        dsc: '30 softgels',
      },
      {
        sku: 'FREE_VITC',
        name: 'Vitamin C',
        image_url:
          'https://m2stage.relieffactor.com/media/catalog/product/s/h/shield-vitc.jpg',
        productId: mappedProductIDs['FREE_VITC'][db],
        dsc: '30 tablets',
      },
    ],
    maxQty: 2,
    quantityInfoMsg: 'You have reached maximum quantity',
    purchaseOption: 'Subscribe & Save',
    quoteParams: {
      productId: mappedProductIDs['REL-60-02'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: '98eb55e1-658b-4f2e-a71e-844b093699b1',
      offerId: '0a9a4a04-20bb-4654-afd4-843dc4ebcade',
      triggerType: '3', // Need to check before going to Production
      //      existingItemsOffer: [],
    },
    disclaimer:
      "When you purchase <span>Relief Factor</span> you will automatically receive a full month’s supply <span>(60 packets)</span> of <span>Relief Factor</span> shipped in 3 weeks and then every month thereafter unless you cancel. Your card will be charged $79.95 + $6.95 S&H each time your next supply ships. To cancel, call <a href='tel:+1833-888-1533'>833-888-1533</a> or send an email to <a href='mailto:contactus@relieffactor.com'>contactus@relieffactor.com</a> at least 1 day before your next supply ships.",
    imageUrl:
      'https://m2beta-frontend.relieffactor.com/media/catalog/product/r/f/rfqs-wfreeguide_1__1__2.png',
  },
  [RF_RELIEF_FACTOR_60_CT]: {
    key: RF_RELIEF_FACTOR_60_CT,
    title: 'Relief Factor',
    name: 'Relief Factor (60 count)',
    productName: 'Relief Factor (60 count)',
    category: 'REL',
    isSubscription: true,
    displayPrice: 19.95,
    initialPrice: '0.00',
    recurringPrice: '79.95',
    single_price: '93.95',
    sku: 'REL-60-02',
    productId: mappedProductIDs['REL-60-02'][db],
    CA_sku: 'REL-60-03',
    CA_product_id: mappedProductIDs['REL-60-03'][db],
    free_products: [],
    maxQty: 2,
    quantityInfoMsg: 'You have reached maximum quantity',
    purchaseOption: 'Subscribe & Save',
    quoteParams: {
      productId: mappedProductIDs['REL-60-02'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: '98eb55e1-658b-4f2e-a71e-844b093699b1',
      offerId: '69d332a4-29b1-41db-b8a7-45ab30796cb6',
      triggerType: 3,
      //      existingItemsOffer: [],
    },
    disclaimer:
      "When you purchase <span>Relief Factor</span> you will automatically receive a full month’s supply <span>(60 packets)</span> of <span>Relief Factor</span> shipped in 3 weeks and then every month thereafter unless you cancel. Your card will be charged $79.95 + $6.95 S&H each time your next supply ships. To cancel, call <a href='tel:+1833-888-1533'>833-888-1533</a> or send an email to <a href='mailto:contactus@relieffactor.com'>contactus@relieffactor.com</a> at least 1 day before your next supply ships.",
    imageUrl:
      'https://m2beta-frontend.relieffactor.com/media/catalog/product/r/f/rfqs-wfreeguide_1__1__2.png',
  },
  [RF_RELIEF_FACTOR_60_CT_SINGLE]: {
    key: RF_RELIEF_FACTOR_60_CT_SINGLE,
    title: 'Relief Factor',
    name: 'Relief Factor (60 count)',
    productName: 'Relief Factor (60 count)',
    category: 'REL',
    isSubscription: false,
    displayPrice: 93.95,
    initialPrice: '0.00',
    recurringPrice: '79.95',
    single_price: '93.95',
    sku: 'REL-60-02',
    productId: mappedProductIDs['REL-60-02'][db],
    CA_sku: 'REL-60-03',
    CA_product_id: mappedProductIDs['REL-60-03'][db],
    free_products: [],
    maxQty: 10,
    quantityInfoMsg: 'You have reached maximum quantity',
    purchaseOption: 'Single Purchase',
    quoteParams: {
      productId: mappedProductIDs['REL-60-02'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: '98eb55e1-658b-4f2e-a71e-844b093699b1',
      offerId: '69d332a4-29b1-41db-b8a7-45ab30796cb6',
      //      triggerType: '',
      //      existingItemsOffer: [],
    },
    disclaimer:
      "When you purchase <span>Relief Factor</span> you will automatically receive a full month’s supply <span>(60 packets)</span> of <span>Relief Factor</span> shipped in 3 weeks and then every month thereafter unless you cancel. Your card will be charged $79.95 + $6.95 S&H each time your next supply ships. To cancel, call <a href='tel:+1833-888-1533'>833-888-1533</a> or send an email to <a href='mailto:contactus@relieffactor.com'>contactus@relieffactor.com</a> at least 1 day before your next supply ships.",
    imageUrl:
      'https://m2beta-frontend.relieffactor.com/media/catalog/product/r/f/rfqs-wfreeguide_1__1__2.png',
  },
  [RF_GO_FACTOR]: {
    key: RF_GO_FACTOR,
    title: 'Go Factor',
    name: 'Go Factor (60 count)',
    productName: 'Go Factor (60 count)',
    category: 'GOF',
    isSubscription: true,
    displayPrice: 29.95,
    initialPrice: '0.00',
    recurringPrice: '29.95',
    single_price: '34.95',
    sku: 'GOF-60-01',
    productId: mappedProductIDs['GOF-60-01'][db],
    free_products: [],
    maxQty: 2,
    quantityInfoMsg: 'You have reached maximum quantity',
    purchaseOption: 'Subscribe & Save',
    quoteParams: {
      productId: mappedProductIDs['GOF-60-01'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: '61cb60a9-3f56-4cfd-8b11-69a2ff790441',
      offerId: 'd5bca645-9f91-4d04-903f-d889656fb7bc',
      //      triggerType: '',
      //      existingItemsOffer: [],
    },
    disclaimer:
      'When you purchase <span>Go Factor</span> you will automatically receive the regular <span>60-capsules</span> supply shipped in 30 days and then monthly thereafter unless you cancel. Your card will be charged $29.95 + applicable state taxes each time your supply ships. To cancel, call <a href="tel:+1833-888-1533">833-888-1533</a> or send an email to <a href="mailto:contactus@relieffactor.com">contactus@relieffactor.com</a> at least 1 day before your next supply ships.',
    imageUrl:
      'https://m2beta.relieffactor.com/media/catalog/product/e/n/energy-pills-single.png',
  },
  [RF_GO_FACTOR_SINGLE]: {
    key: RF_GO_FACTOR_SINGLE,
    title: 'Go Factor',
    name: 'Go Factor (60 count)',
    productName: 'Go Factor (60 count)',
    category: 'GOF',
    isSubscription: false,
    displayPrice: 34.95,
    initialPrice: '0.00',
    recurringPrice: '29.95',
    single_price: '34.95',
    sku: 'GOF-60-01',
    productId: mappedProductIDs['GOF-60-01'][db],
    free_products: [],
    maxQty: 10,
    quantityInfoMsg: 'You have reached maximum quantity',
    purchaseOption: 'Single Purchase',
    quoteParams: {
      productId: mappedProductIDs['GOF-60-01'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: '61cb60a9-3f56-4cfd-8b11-69a2ff790441',
      offerId: 'd5bca645-9f91-4d04-903f-d889656fb7bc',
      //      triggerType: '',
      //      existingItemsOffer: [],
    },
    disclaimer:
      'When you purchase <span>Go Factor</span> you will automatically receive the regular <span>60-capsules</span> supply shipped in 30 days and then monthly thereafter unless you cancel. Your card will be charged $29.95 + applicable state taxes each time your supply ships. To cancel, call <a href="tel:+1833-888-1533">833-888-1533</a> or send an email to <a href="mailto:contactus@relieffactor.com">contactus@relieffactor.com</a> at least 1 day before your next supply ships.',
    imageUrl:
      'https://m2beta.relieffactor.com/media/catalog/product/e/n/energy-pills-single.png',
  },
  [RF_ZEN_FACTOR]: {
    key: RF_ZEN_FACTOR,
    title: 'Zen Factor',
    name: 'Zen Factor (60 count)',
    productName: 'Zen Factor (60 count)',
    category: 'ZEN',
    isSubscription: true,
    displayPrice: 21.95,
    initialPrice: '0.00',
    recurringPrice: '21.95',
    single_price: '28.95',
    sku: 'ZEN-60-01',
    productId: mappedProductIDs['ZEN-60-01'][db],
    CA_sku: 'ZEN-60-02',
    CA_product_id: mappedProductIDs['ZEN-60-02'][db],
    free_products: [],
    maxQty: 2,
    quantityInfoMsg: 'You have reached maximum quantity',
    purchaseOption: 'Subscribe & Save',
    quoteParams: {
      productId: mappedProductIDs['ZEN-60-01'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: 'b5e92d67-92c8-4d8d-9f35-be925b99633f',
      offerId: '4a35194f-d1cb-4e59-a956-b1135961184f',
      //      triggerType: '',
      //      existingItemsOffer: [],
    },
    disclaimer: `When you purchase <span>Zen Factor</span> you will automatically receive the regular <span>60-capsule</span> supply shipped in 30 days and then monthly thereafter unless you cancel. Your card will be charged $21.95 + applicable state taxes each time your supply ships. To cancel, call <a href="tel:+1833-888-1533">833-888-1533</a>
       or send an email to <a href='mailto:contactus@relieffactor.com'>contactus@relieffactor.com</a> at least 1 day before your next supply ships.`,
    imageUrl:
      'https://m2beta.relieffactor.com/media/catalog/product/r/f/rfcalm.png',
  },
  [RF_ZEN_FACTOR_SINGLE]: {
    key: RF_ZEN_FACTOR_SINGLE,
    title: 'Zen Factor',
    name: 'Zen Factor (60 count)',
    productName: 'Zen Factor (60 count)',
    category: 'ZEN',
    isSubscription: false,
    displayPrice: 28.95,
    initialPrice: '0.00',
    recurringPrice: '21.95',
    single_price: '28.95',
    sku: 'ZFA-60-01',
    productId: mappedProductIDs['ZEN-60-01'][db],
    CA_sku: 'ZEN-60-02',
    CA_product_id: mappedProductIDs['ZEN-60-02'][db],
    free_products: [],
    maxQty: 10,
    quantityInfoMsg: 'You have reached maximum quantity',
    purchaseOption: 'Single Purchase',
    quoteParams: {
      productId: mappedProductIDs['ZEN-60-01'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: 'b5e92d67-92c8-4d8d-9f35-be925b99633f',
      offerId: '4a35194f-d1cb-4e59-a956-b1135961184f',
      //      triggerType: '',
      //      existingItemsOffer: [],
    },
    disclaimer: `When you purchase <span>Zen Factor</span> you will automatically receive the regular <span>60-capsule</span> supply shipped in 30 days and then monthly thereafter unless you cancel. Your card will be charged $21.95 + applicable state taxes each time your supply ships. To cancel, call <a href="tel:+1833-888-1533">833-888-1533</a>
    or send an email to <a href='mailto:contactus@relieffactor.com'>contactus@relieffactor.com</a> at least 1 day before your next supply ships.`,
    imageUrl:
      'https://m2beta.relieffactor.com/media/catalog/product/r/f/rfcalm.png',
  },
  [RF_Z_FACTOR]: {
    key: RF_Z_FACTOR,
    title: 'Z Factor',
    name: 'Z Factor (60 count)',
    productName: 'Z Factor (60 count)',
    category: 'ZFA',
    isSubscription: true,
    displayPrice: 19.95,
    initialPrice: '19.95',
    recurringPrice: '29.95',
    single_price: '36.95',
    sku: 'ZFA-60-01',
    productId: mappedProductIDs['ZFA-60-01'][db],
    free_products: [],
    maxQty: 2,
    quantityInfoMsg: 'You have reached maximum quantity',
    purchaseOption: 'Subscribe & Save',
    quoteParams: {
      productId: mappedProductIDs['ZFA-60-01'][db],
      //customerId: '',
      quantity: 1,
      planId: '11d07b11-0cd4-45be-b42e-4285f2d5b4a7',
      offerId: 'b80bb1fc-1fef-42a3-8432-90b1b429777a',
      //      existingItemsOffer: [],
    },
    disclaimer: `When you purchase <span>Z Factor</span> you will automatically receive the regular <span>60-capsule</span> supply shipped in 30 days and then monthly thereafter unless you cancel. Your card will be charged $29.95 + applicable state taxes each time your supply ships. To cancel, call <a href="tel:+1833-888-1533">833-888-1533</a> or send an email to <a href='mailto:contactus@relieffactor.com'>contactus@relieffactor.com</a> at least 1 day before your next supply ships.`,
    imageUrl:
      'https://m2stage.relieffactor.com/media/catalog/product/r/f/rf-sleep-500.png',
  },
  [RF_Z_FACTOR_SINGLE]: {
    key: RF_Z_FACTOR_SINGLE,
    title: 'Z Factor',
    name: 'Z Factor (60 count)',
    productName: 'Z Factor (60 count)',
    category: 'ZFA',
    isSubscription: false,
    displayPrice: 36.95,
    initialPrice: '19.95',
    recurringPrice: '29.95',
    single_price: '36.95',
    sku: 'ZFA-60-01',
    productId: mappedProductIDs['ZFA-60-01'][db],
    free_products: [],
    maxQty: 10,
    quantityInfoMsg: 'You have reached maximum quantity',
    purchaseOption: 'Single Purchase',
    quoteParams: {
      productId: mappedProductIDs['ZFA-60-01'][db],
      //customerId: '',
      quantity: 1,
      //      couponCode: '',
      //      freeProducts: [],
      planId: '11d07b11-0cd4-45be-b42e-4285f2d5b4a7',
      offerId: 'b80bb1fc-1fef-42a3-8432-90b1b429777a',
      //      triggerType: '',
      //      existingItemsOffer: [],
    },
    disclaimer: `When you purchase <span>Z Factor</span> you will automatically receive the regular <span>60-capsule</span> supply shipped in 30 days and then monthly thereafter unless you cancel. Your card will be charged $29.95 + applicable state taxes each time your supply ships. To cancel, call <a href="tel:+1833-888-1533">833-888-1533</a> or send an email to <a href='mailto:contactus@relieffactor.com'>contactus@relieffactor.com</a> at least 1 day before your next supply ships.`,
    imageUrl:
      'https://m2stage.relieffactor.com/media/catalog/product/r/f/rf-sleep-500.png',
  },
};

//? Updated products on : 06-09-2023 by Alfred Joseph
const products = {
  ...oldProducts,
  ...newProducts,
};

export { products, productsKeyConstants, ProductsCategory };
